import { createSelector } from 'reselect'

const getCloudConfig = (state) => state.cloud.vodConfig
const getCurrentOptions = (state) => state.upload.vodUploadOptions

export default createSelector([getCloudConfig, getCurrentOptions], (vodConfig, sendOptions={}) => {
  // Set defaults based on vodConfig
  let toReturn = {...sendOptions}
  /* Commented out on request by Nathan. Default to not automatically sending and not automatically captioning
  if(vodConfig) {
    if(!("doSend" in toReturn)) {
      toReturn.doSend = true
    }
    if(!("caption_file" in toReturn)) {
      toReturn.caption_file = (vodConfig && vodConfig.conf && vodConfig.conf.captions === "pro")
    }
  }
  */
  return toReturn
})
