import React, {PureComponent} from 'react'
import {capitalizeMetadata} from 'helpers/metadata_helpers'
import {Table, Button, Dropdown, Modal} from 'semantic-ui-react'
import MetadataEntry from "components/Metadata/MetadataEntry"

export default class PopulateMetadata extends PureComponent {

  state = {open: false}

  addMetadata = (tag='') => {
    let {changeMetadata} = this.props
    if(tag) {
      changeMetadata(tag, '')
    } else {
      this.props.prompt('Add which metadata tag?', (tag) => {
        if(tag) {
          changeMetadata(tag, '')
        }
      }, {filter: /[.$]/g})
    }
  }

  handleOpen = () => {this.setState({open: true})}
  handleClose = () => {this.setState({open: false})}

  render() {

    let {metadata,
      metadataTags,
      changeMetadata,
      resetMetadata,
      timezone} = this.props

    let MetadataItems = Object.entries(metadata).map(([key, value]) => {
      let tag = metadataTags[key]
      return (
        <MetadataEntry key={key}
          name={key}
          value={value}
          metadataTag={tag}
          onChange={changeMetadata}
          timezone={timezone}/>
      )
    })

    let MetadataOptions = Object.entries(metadataTags).map(([key, value]) => {
      if(key === "guid") {
        return null
      }
      let tagDisplayName = capitalizeMetadata(value.display)
      let handleMetaClick = () => {this.addMetadata(key)}
      return <Dropdown.Item key={key} onClick={handleMetaClick}>{tagDisplayName}</Dropdown.Item>
    }).filter((item) => item !== null)

    let handleRevert = () => {
      resetMetadata()
    }

    return (
      <Modal id='populateMetadataPane' open={this.state.open} trigger={<Button type="button" onClick={this.handleOpen}>Populate Metadata</Button>}>
        <Modal.Header>Populate Metadata</Modal.Header>
        <Modal.Content>
          <div id='populateMetadataButtonPane'>
            <Dropdown icon='plus' title='Add Metadata Field' scrolling button>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => this.addMetadata()}>Custom Tag...</Dropdown.Item>
                {MetadataOptions}
              </Dropdown.Menu>
            </Dropdown>
            <Button icon='undo' title='Revert' onClick={handleRevert}/>
          </div>
          <Table columns='4' attached='top'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Field</Table.HeaderCell>
                <Table.HeaderCell>Value</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
          </Table>
          <div id='populateMetadataTablePane'>
            <Table attached='bottom' columns='4'>
              <Table.Body>
                {MetadataItems}
              </Table.Body>
            </Table>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button color="green" onClick={this.handleClose}>
            Done
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }
}
