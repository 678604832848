import React, {PureComponent} from 'react'

import deferRender from 'components/higher_order_components/deferredRenderComponent'
import ScheduleTable from './ScheduleTable'
import ScheduleTimeSelector from './ScheduleTimeSelector'
import ScheduleButtonPanel from './ScheduleButtonPanel'
import ScheduleDefaultsPanel from './ScheduleDefaultsPanel'
import CreateScheduleForm from './CreateScheduleForm'

import {SCHEDULE_TYPES} from "helpers/schedule_helpers"

import './SchedulePanel.css'

const DeferredScheduleTable = deferRender(ScheduleTable)


class SchedulePanel extends PureComponent {

  constructor(props) {
    super(props)
    this.handleScrollToTime = this.handleScrollToTime.bind(this)
    this.handlePasteToTime = this.handlePasteToTime.bind(this)
    this.handleAddToTime = this.handleAddToTime.bind(this)
  }

  handleScrollToTime() {
    let {showSubseconds, actions} = this.props
    let {scrollToTime} = actions
    actions.prompt('What time do you want to scroll to?', (timeToScroll) => {
      if(timeToScroll !== null) {
        scrollToTime(timeToScroll)
      }
    }, {type: 'time', subseconds: showSubseconds})
  }

  handlePasteToTime() {
    let {viewTime, showSubseconds, scheduleData} = this.props
    let {pasteClipboardItems} = this.props.actions
    this.props.actions.prompt("What times do you want to paste the items to?", (timesToPaste) => {
      if(timesToPaste !== null) {
        pasteClipboardItems(timesToPaste)
      }
    }, {type: 'scheduleDate',
      scheduleType: scheduleData.type,
      intervalBasis: scheduleData.intervalBasis,
      intervalDuration: scheduleData.intervalDuration,
      viewTime,
      showMilliseconds: showSubseconds})
  }

  handleAddToTime() {
    let {viewTime, scheduleData, showSubseconds} = this.props
    let {addScheduleItems} = this.props.actions
    this.props.actions.prompt("What times do you want to add the items to?", (timesToAdd) => {
      if(timesToAdd !== null) {
        addScheduleItems(timesToAdd)
      }
    }, {type: 'scheduleDate',
      scheduleType: scheduleData.type,
      intervalBasis: scheduleData.intervalBasis,
      intervalDuration: scheduleData.intervalDuration,
      viewTime,
      showMilliseconds: showSubseconds})
  }

  handleSelectAll = () => {
    let {displaySchedule} = this.props
    let start, end;
    for(let i = 0; i < displaySchedule.length; i++) {
      if(displaySchedule[i].key) {
        // Selecting the key of a schedule block does nothing; need to select only by item keys.
        if(displaySchedule[i].type === "block") {
          if(displaySchedule[i].body.contains.length) {
            start = displaySchedule[i].body.contains[0].key
          } else {
            continue
          }
        } else {
          start = displaySchedule[i].key
        }
        break;
      }
    }
    for(let i = displaySchedule.length - 1; i >= 0; i--) {
      if(displaySchedule[i].key) {
        // Selecting the key of a schedule block does nothing; need to select only by item keys.
        if(displaySchedule[i].type === "block") {
          if(displaySchedule[i].body.contains.length) {
            end = displaySchedule[i].body.contains[displaySchedule[i].body.contains.length - 1].key
          } else {
            continue
          }
        } else {
          end = displaySchedule[i].key
        }
        break;
      }
    }
    if(start && end) {
      this.props.actions.selectScheduleItems([end], {range: true, rangeStart: start, mode: 'select'})
    }
  }

  render() {
    let {hasErrors,
      hasSelectedFiles,
      unsaved,
      actions,
      playingDefault,
      scheduleData,
      displaySchedule,
      shouldUndo,
      shouldRedo,
      viewTime,
      datelist,
      filename,
      clipboard,
      scrollEvent,
      scrollTop,
      showSubseconds} = this.props

    if(!scheduleData) {
      return (
        <div id='schedulePanel'>
          <CreateScheduleForm createSchedule={actions.createNewSchedule} openApplication={actions.openApplication} filename={filename}/>
        </div>
      )
    }

    let {type,
      defaults,
      intervalDuration,
      intervalBasis} = scheduleData

    let itemActions = {
      ...actions,
      messages: {
        prompt: actions.prompt,
        promptAsync: actions.promptAsync,
        confirmAsync: actions.confirmAsync
      }
    }

    // Check if any item is unselected, but only if that item is not an empty item/block
    let unselected = displaySchedule.some((item) => (
      item.type !== 'empty' &&
      !item.selected &&
      !(item.type === 'block' && (
        !item.body.contains.length ||
        !item.body.contains.some((child) => child.type !== 'empty')))
    ))

    let defaultErrors = scheduleData.errors && scheduleData.errors.filter((err) => err.itemKey && err.itemKey.startsWith("DEFAULT:"))

    if(SCHEDULE_TYPES.includes(type)) {
      return (
        <div id='schedulePanel'>
          <ScheduleTimeSelector type={type}
            viewDate={viewTime}
            datelist={datelist}
            intervalDuration={intervalDuration}
            intervalBasis={intervalBasis}
            changeView={actions.changeScheduleView}
            changeIntervalDuration={actions.changeIntervalDuration}
            changeIntervalBasis={actions.changeIntervalBasis}/>
          <ScheduleDefaultsPanel defaults={defaults}
            type={type}
            viewTime={viewTime}
            errors={defaultErrors}
            playingDefault={playingDefault}
            hasSelectedFiles={hasSelectedFiles}
            setDefaultItem={actions.setDefaultItem}
            navigateToFile={actions.navigateToFile}
            removeDefaultItem={actions.removeDefaultItem}/>
          <ScheduleButtonPanel hasErrors={hasErrors}
            unselected={unselected}
            shouldUndo={shouldUndo}
            shouldRedo={shouldRedo}
            unsaved={unsaved}
            clipboard={clipboard}
            type={type}
            viewTime={viewTime}
            intervalDuration={intervalDuration}
            intervalBasis={intervalBasis}
            {...actions}
            selectAll={this.handleSelectAll}
            scrollToTime={this.handleScrollToTime}
            addScheduleItems={this.handleAddToTime}
            pasteClipboardItems={this.handlePasteToTime}/>
          <DeferredScheduleTable schedule={displaySchedule}
            clipboard={clipboard}
            scrollEvent={scrollEvent}
            scrollTop={scrollTop}
            itemActions={itemActions}
            showSubseconds={showSubseconds}
            hasSelectedFiles={hasSelectedFiles}
            addScheduleItems={actions.addScheduleItems}
            pasteScheduleItems={actions.pasteClipboardItems}
            selectScheduleItems={actions.selectScheduleItems}/>
        </div>
      )
    } else {
      return (
        <div id='schedulePanel'>
          <CreateScheduleForm createSchedule={actions.createNewSchedule} openApplication={actions.openApplication} filename={filename}/>
        </div>
      )
    }
  }

}

export default SchedulePanel
