import {fetchFromServer, fetchFileFromServer} from 'helpers/net_helpers'
import {loadFileData} from 'redux/file_list'
import {messages} from 'redux/messages'
import {getIn, encodeURIFilepath, validateURL} from 'helpers/general_helpers'
import {dataPath} from 'helpers/library_helpers'
import {parseMetadataTemplate, parseContentWindow, serializeContentWindow, sanitizeMetadataTags} from 'helpers/metadata_helpers'
import tabbedReducer from 'redux/higher_order_reducers/tabbedReducer'
import loaderReducer from 'redux/higher_order_reducers/loaderReducer'
import {actions as loading} from 'redux/higher_order_reducers/loaderReducer'

export const CHANGE_METADATA = Symbol('change metadata')
export const LOAD_FILE_METADATA = Symbol('load file metadata')
export const RESET_SWAP_METADATA = Symbol('reset swap metadata')
export const APPLY_TEMPLATE = Symbol('apply template')
export const SET_SWAP_DATA = Symbol('set swap data')

export const METADATA_CHANGE_TAB = Symbol('metadata change tab')
export const METADATA_CREATE_TAB = Symbol('metadata create tab')
export const METADATA_DELETE_TAB = Symbol('metadata delete tab')

export const actions = {

  dispatchCall: () => {
    return async (dispatch, getState) => {
      let {filepath, filename} = getState().local
      let fullpath = [...filepath, filename]
      let jobId = dispatch(loading.startLoading(getState().local._loaderID))

      await dispatch.global(loadFileData(filepath))
      await dispatch(actions.loadFileMetadata(fullpath))
      dispatch({type: RESET_SWAP_METADATA})
      dispatch(loading.finishLoading(getState().local._loaderID, jobId))
      dispatch.global(messages.alert(`Metadata for /${fullpath.join('/')} saved!`, {level: 'success'}))
    }

  },

  saveMetadata: () => {
    console.log("saveMetadata")

    return async (dispatch, getState) => {
      let {filepath, filename, swapData, metadata} = getState().local
      let fullpath = [...filepath, filename]

      // SPECIAL CASE: "Content Window Open" and "Content Window Close" must be formatted as YYYY-MM-DD HH:mm:ss UTC
      if("content window open" in swapData) {
        let contentWindowOpen = swapData["content window open"]

        if(contentWindowOpen != null) {
          swapData = {
            ...swapData,
            "content window open": serializeContentWindow(contentWindowOpen)
          }
        }
      }
      if("content window close" in swapData) {
        let contentWindowClose = swapData["content window close"]

        if(contentWindowClose != null) {
          swapData = {
            ...swapData,
            "content window close": serializeContentWindow(contentWindowClose)
          }
        }
      }

      if(swapData["external link"] && !validateURL(swapData["external link"])) {
        let keepGo = await dispatch.global(messages.confirmAsync("The value you entered for the External Link metadata tag is not a valid url. Are you sure you want to save the metadata as is?"))
        if(!keepGo) {
          return
        }
      }

      swapData = sanitizeMetadataTags(swapData)

      // Sanitization of original metadata
      let sanitizedMetadata = sanitizeMetadataTags(metadata)
      for(let key of [...Object.keys(metadata), ...Object.keys(sanitizedMetadata)]) {
        if(!(key in metadata) && (key in sanitizedMetadata)) {
          swapData[key] = swapData[key] || sanitizedMetadata[key]
        } else if((key in metadata) && !(key in sanitizedMetadata)) {
          swapData[key] = null
        }
      }

      let saveData = JSON.stringify(swapData)
      let res = await fetchFromServer(`/v2/other/metadata-editor-save/${encodeURIFilepath(fullpath).join('/')}`, {
        method: 'POST',
        body: saveData,
        headers: {
          'Content-Type': 'application/json'
        }
      })
      if(!res.ok) {
        console.log("Failed saving metadata")
        dispatch.global(messages.alert("Error saving metadata!", {level: 'error'}))
      } else {
        let jobId = dispatch(loading.startLoading(getState().local._loaderID))

        await dispatch.global(loadFileData(filepath))
        await dispatch(actions.loadFileMetadata(fullpath))
        dispatch({type: RESET_SWAP_METADATA})
        dispatch(loading.finishLoading(getState().local._loaderID, jobId))
        dispatch.global(messages.alert(`Metadata for /${fullpath.join('/')} saved!`, {level: 'success'}))
      }
    }
  },

  /**
   * Changes the swap metadata
   * @param {string} key The metadata key to change
   * @param {string} value The value to change the given key to
   */
  changeMetadata: (key, value) => (
    {
      type: CHANGE_METADATA,
      payload: {
        key,
        value
      }
    }
  ),

  /**
   * Changes the filepath of the current file whose metadata is being edited. Also resets swap data.
   * @param {array} rpath The rpath of the file to edit.
   */
  loadFileMetadata: (rpath) => {
    return async (dispatch, getState) => {
      if(typeof rpath === 'string') {
        rpath = rpath.split('/')
      }
      let metadata = {}
      let jobId = dispatch(loading.startLoading(getState().local._loaderID))
      let response = await fetchFromServer(`/v2/files/metadata/${encodeURIFilepath(rpath).join('/')}`)
      if(response.ok) {
        metadata = await response.json()
      // If we already have metadata from earlier, use it as a backup in case the metadata
      // fetch errors
      } else if (getIn(getState().global.file_list.fileData, dataPath(rpath))) {
        metadata = getIn(getState().global.file_list.fileData, dataPath(rpath).concat('metadata'))
      }

      // SPECIAL CASE: "Content Window Open" and "Content Window Close" must be formatted as YYYY-MM-DD HH:mm:ss UTC
      if("content window open" in metadata) {
        let contentWindowOpen = metadata["content window open"]
        try {
          contentWindowOpen = parseContentWindow(contentWindowOpen)
        } catch (err) {
          // Ok, just try parsing it as a date?
          contentWindowOpen = new Date(contentWindowOpen)
        }
        metadata = {
          ...metadata,
          "content window open": contentWindowOpen
        }
      }
      if("content window close" in metadata) {
        let contentWindowclose = metadata["content window close"]
        try {
          contentWindowclose = parseContentWindow(contentWindowclose)
        } catch (err) {
          // Ok, just try parsing it as a date?
          contentWindowclose = new Date(contentWindowclose)
        }
        metadata = {
          ...metadata,
          "content window close": contentWindowclose
        }
      }

      dispatch(loading.finishLoading(getState().local._loaderID, jobId))
      dispatch({
        type: LOAD_FILE_METADATA,
        payload: {
          rpath,
          metadata
        }
      })
    }
  },

  /**
   * Removes swap data, reverting to just the file's current metadata
   */
  resetSwapMetadata: () => {
    return async (dispatch) => {
      let result = await dispatch.global(messages.confirmAsync('Are you sure you want to revert all unsaved changes?'))
      if(result) {
        dispatch({type: RESET_SWAP_METADATA})
      }
    }
  },

  /**
   * Prompts the user to select a metadata template, and then applies metadata from that template over the current metadata.
   */
  applyMetadataTemplate: () => {
    return async (dispatch, getState) => {
      let templatePath = await dispatch.global(messages.promptAsync("Select a metadata template to apply", {
        type: "path",
        initialValue: {directory: ["mnt", "main", "Metadata Templates"]},
        validate: (value) => {
          let rpath = [...value.directory, value.filename]
          // Check if it is actually a metadata template that exists
          if(getIn(getState().global.file_list.fileData, dataPath(rpath))) {
            let templateMetadata = getIn(getState().global.file_list.fileData, dataPath(rpath).concat("metadata"))
            return templateMetadata["file type"] === "application/x-castus-metadata-template"
          }
          return false
        },
        invalidText: "The selected file is not a metadata template"
      }))
      if(templatePath === null) {
        return
      }
      let filepath = [...templatePath.directory, templatePath.filename].join("/")
      let jobId = dispatch(loading.startLoading(getState().local._loaderID))
      let response = await fetchFileFromServer(filepath)
      if(!response.ok) {
        let errMsg = await response.text()
        console.error(`Error retrieving metadata template file: ${errMsg}`)
        dispatch.global(messages.alert(`Error retrieving metadata template at /${filepath.join('/')}: ${errMsg}`, {level: 'error'}))
        return
      }
      let template = await response.text()
      try {
        template = parseMetadataTemplate(template)
        template = sanitizeMetadataTags(template)
      } catch (err) {
        if(err.type === 'NOT_METADATA_TEMPLATE') {
          dispatch.global(messages.alert(`File /${filepath.join('/')} is not a metadata template.`, {level: 'error'}))
          return
        } else {
          throw err
        }
      }
      dispatch(loading.finishLoading(getState().local._loaderID, jobId))
      dispatch({type: APPLY_TEMPLATE, payload: template})
    }
  }

}

export const initialState = {
  filepath: [],                        // folder path of file being edited
  filename: '',                        // name of file being edited
  metadata: {},                        // current metadata of file being edited
  swapData: {},                        // object containing changes made to file's metadata that have not yet been saved.
}

export const reducer = (state=initialState, action) => {
  let {type, payload} = action

  switch(type) {
    case CHANGE_METADATA:
      let {key, value} = payload
      return {
        ...state,
        swapData: {
          ...state.swapData,
          [key]: value
        }
      }
    case LOAD_FILE_METADATA: {
      let {rpath, metadata} = payload
      return {
        ...state,
        filepath: rpath.slice(0, -1),
        filename: rpath.slice(-1)[0],
        metadata,
      }
    }
    case RESET_SWAP_METADATA:
      return {
        ...state,
        swapData: {}
      }
    case APPLY_TEMPLATE:
      return {
        ...state,
        swapData: {...state.swapData, ...payload}
      }
    case SET_SWAP_DATA:
      return {
        ...state,
        swapData: payload
      }
    default:
      return state
  }
}

export const changeTab = (index) => ({
  type: METADATA_CHANGE_TAB,
  payload: index
})

export const createTab = (path) => {
  let payload = {}
  if(path) {
    payload = {
      filepath: path.slice(0, -1),
      filename: path.slice(-1).join()
    }
  }
  return {
    type: METADATA_CREATE_TAB,
    payload
  }
}

export const deleteTab = (index) => ({
  type: METADATA_DELETE_TAB,
  payload: index
})

const TAB_DISPLAY = (props) => {
  if(props.filename) {
    return props.filename
  } else {
    return '(UNKNOWN FILE)'
  }
}

const TAB_IS_UNSAVED = (props) => {
  return Object.keys(props.swapData).length > 0
}

const onTabCreated = () => {
  return (dispatch, getState) => {
    let {filepath, filename} = getState().local
    if(filepath.length > 0 && filename) {
      let fullpath = [...filepath, filename]
      dispatch(actions.loadFileMetadata(fullpath.join('/')))
    }
  }
}

const loadingReducer = loaderReducer(reducer, initialState);

export default tabbedReducer('metadata_editor',
  loadingReducer,
  initialState,
  {
    changeTab: METADATA_CHANGE_TAB,
    createTab: METADATA_CREATE_TAB,
    deleteTab: METADATA_DELETE_TAB,
    display: TAB_DISPLAY,
    tabActions: actions,
    isUnsaved: TAB_IS_UNSAVED,
    onTabCreated
  }
)
