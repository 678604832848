import React, {PureComponent} from 'react'
import {Form, Input} from 'semantic-ui-react'

export default class StringPrompt extends PureComponent {

  stringInput = React.createRef()

  componentDidMount() {
    if(this.stringInput.current) {
      this.stringInput.current.focus()
    }
  }

  onChange = (value) => {
    let {changeValue, options} = this.props
    if(options && options.filter && options.filter instanceof RegExp) {
      value = value.replace(options.filter, "")
    }
    changeValue(value)
  }

  render() {
    let {value} = this.props
    return (
      <Form>
        <Form.Field>
          <Input value={value}
            ref={this.stringInput}
            onChange={(e, data) => {this.onChange(data.value)}}/>
        </Form.Field>
      </Form>
    )
  }

}
