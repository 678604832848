import React, {useState, useEffect} from 'react'
import {Input} from 'semantic-ui-react'
import DatePicker from 'react-datepicker'
import TimeInput from './TimeInput'
// TODO: For menu clock to display correct time. Moment needs to be phased out though.
import moment from 'moment-timezone'

import "./DateTimeInput.css"

const timeInputRegex = /(\d{1,2})?(?::(\d{0,2}))?(?::(\d{0,2}))?(?:\.(\d{0,3}))?(?: ([ap]m))?/i

function timeStringToDateObject(timeString) {
  let timeInput = timeInputRegex.exec(timeString)
  if(!timeInput) {
    return null
  }
  let returnDate = new Date()
  let [hours, minutes, seconds, milliseconds, period] = timeInput.slice(1).map(val => val || "0")
  hours = parseInt(hours, 10)
  if(hours === 12 && period.toLowerCase() === "am") {
    hours = 0
  } else if (hours !== 12 && period.toLowerCase() === "pm") {
    hours += 12
  }
  minutes = parseInt(minutes, 10)
  seconds = parseInt(seconds, 10)
  milliseconds = parseInt(milliseconds, 10)
  returnDate.setHours(hours, minutes, seconds, milliseconds)
  return returnDate;
}

function combineDateAndTime(date, time) {
  let timeDate = timeStringToDateObject(time)
  let combinedDate = date ? new Date(date) : new Date()
  if(timeDate) {
    combinedDate.setHours(timeDate.getHours(), timeDate.getMinutes(), timeDate.getSeconds(), timeDate.getMilliseconds())
  }
  return combinedDate
}

function DateTimeInput(props) {

  let [date, setDate] = useState(null);
  let [time, setTime] = useState("");

  useEffect(() => {
    let value = props.value
    if(typeof value === "number") {
      value = new Date(value)
    }
    if(value instanceof Date) {
      if(isNaN(value)) {
        return
      }
      setDate(value)
      let valueTime = timeInputRegex.exec(value.toLocaleTimeString("en-US"))
      if(valueTime) {
        let [hours, minutes, seconds, milliseconds] = valueTime.slice(1, valueTime.length - 1).map(val => val ? val.replace(/0+([1-9])/, (match, digit) => digit) : "0")
        valueTime = `${hours}:${minutes}:${seconds}.${milliseconds} ${valueTime[valueTime.length - 1]}`
      }
      setTime(valueTime)
    // Handle moment for MenuClock
    } else if (moment.isMoment(value)) {
      setDate(value.toDate())
      setTime(value.format("h:m:s a"))
    }
  }, [props.value])

  let cls = "dateTimeInput"
  if(props.inline) {
    cls += " inline"
  }

  return(
    <div className={cls}>
      <DatePicker wrapperClassName="ui input fluid"
        customInput={<Input fluid/>}
        inline={props.inline}
        selected={date}
        onChange={newDate => {
          setDate(newDate)
          if(props.onChange) {
            props.onChange(combineDateAndTime(newDate, time))
          }
        }}/>
      {// NOTE: if you create a case where prop showMilliseconds = true for TimeInput below, you must also ensure
      // that the value.format call in the Handle moment case of useEffect above uses the format "h:mm:ss.SSS a"
      // If you do not, it will create an infinite loop!
      }
      <TimeInput value={time}
        onChange={newTime => {
          setTime(newTime)
          if(props.onChange) {
            // Ew, more timezone handling
            let newValue = combineDateAndTime(date, newTime)
            if(moment.isMoment(props.value)) {
              newValue = moment(newValue)
              newValue = moment.tz(newValue.format("D M YYYY H:mm:ss"), "D M YYYY H:mm:ss", props.value.tz()).toDate()
            }
            props.onChange(newValue)
          }
        }}/>
    </div>
  )

}

export default DateTimeInput
