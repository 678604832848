/**
 * Reducer for handling displaying popup messages. These actions should be dispatched instead of using the respective global methods of Window.
 *
 * Prompt Types:
 * # {string} "string" - Prompt user to input text. Returns the string entered by the user.
 *  {RegExp} [options.filter] If passed, runs String.replace on the user's input with the given RegExp and "". Unlike options.validate, this is performed as the user is typing
 *
 * # {Moment} "time" - Prompt the user to enter a time of day. Returns a moment of the inputted time (day/month/year will be default for a new moment). Only a valid moment will be returned.
 *  {boolean} [options.subseconds=false] If true, allow the user to input milliseconds. By default, only hour, minute, second, and am/pm can be inputted.
 *
 * # {Moment} "duration" - Prompts the user to input a duration. Returns a moment where the time in H:mm:ss is the duration entered. Only a valid moment will be returned.
 *  {boolean} [options.subseconds=false] If true, allow user to input milliseconds. By default, only allows hours, minutes, and seconds
 *
 * # {Array} "aspectRatio" - Prompts the user to input an aspect ratio as two integer numbers: width and height.
 *  The return value will be a array of two numbers: [width, height]. The values of width and height will be set to 1 if they would have been less.
 *
 * # {number} "number" - Prompts the user to enter a number. Returns the number entered by the user.
 *  {boolean} [options.decimal=false] If set to true, allows a floating point number to be entered. If false, the value inputted by the user will be rounded to the nearest integer if it would have been a float.
 *
 * # {{filename: string, directory: Array}} "path" - Prompts the user to select a filepath in the system through a library interface. Returns an object containing the path information (detailed in returnValue below).
 *    {string} returnValue["filename"] - A filename portion of the filepath, entered by the user as a string.
 *    {Array} returnValue["directory"] - The directory portion of the filepath, as an array of strings. Will always represent an absolute filepath.
 *  {boolean} [options.noFilename=false] If set to true, the input for the filename will not be provided to the user, and they will only be prompted to choose a directory.
 *    If this value is true, any value of returnValue["filename"] should be ignored (it should not have a value unless one was provided in initialValue, but no guarentees of this are made).
 *
 * # {string} "password" - Prompt user to input text. Uses an html input of type "password, so the input will be visually censored.
 *
 * # {*} "multipleChoice" - Prompts the user to select between multiple static choices. Return value for each choice must be supplied in options.
 *  {Array} options.choices - Required. An array of objects, each of which represents one of the choices the user may select from.
 *  {string} options.choices[].label - The text label displayed to the user for a given choice
 *  {*} options.choices[].returnValue - The value that will be returned if this option is the one selected by the user
 *
 * # {Array} "scheduleDate" - Prompts the user to select multiple times/dates within a schedule. Returns an array of IntervalTimes (see helpers/IntervalTime/IntervalTime for more information about IntervalTimes).
 *  {string} options.scheduleType - Required. The type of schedule that times are being selected from. See "helpers/Schedule/Schedule" for a list of valid schedule types.
 *  {Moment} options.viewTime - Required. The time of the schedule currently being viewed. Used for things such as determining what days to replicate to in a yearly schedule when selecting all days that fall on a given weekday.
 *  options.intervalBasis - Required. intervalBasis from schedule. See "helpers/Schedule/Schedule" for more info.
 *  options.intervalDuration - Required. intervalDuration from schedule. See "helpers/Schedule/Schedule" for more info.
 *  {boolean} [options.showMilliseconds=false] - If true, allow user to specify times down to the millisecond. By default, only allows up to seconds to be specified (milliseconds will be 0).
 */

export const NEW_MESSAGE = Symbol('new message')
export const DISMISS_MESSAGE = Symbol('dismiss message')
export const NEXT_MESSAGE = Symbol('next message')

/**
 * Message actions are separate and named differently to make
 * importing easier where action may already be imported from another reducer
 */
export const messages = {

  /**
   * Adds an alert message to the queue of messages to be displayed
   * @param {string} message The message to be displayed in the alert
   * @param {object} options Optional parameters
   * @param {string} options.level The level of message to be displayed.
   *  possible options are "success", "info", "warning", and "error".
   * @param {function} options.onDismiss A function to be called when the alert is dismissed.
   *  The function is not passed any parameters.
   */
  alert: (message, options={}) => {
    return (dispatch) => {
      let newAlert = {
        type: 'alert',
        message,
        options
      }
      dispatch(actions.newMessage(newAlert))
    }
  },

  /**
   * Adds a confirmation prompt to the queue of messages to be displayed
   * @param {string} message The message to prompt the user with
   * @param {function} onClose a callback function to call when the user confirms or cancels at the prompt.
   *  The function will be passed a boolean true if the user confirmed or false if the user canceled.
   * @param {object} options Optional parameters
   * @param {string} options.confirmText Text to display on the confirm button instead of "Yes"
   * @param {string} options.cancelText Text to display on the cancel button instead of "No"
   */
  confirm: (message, onClose, options={}) => {
    return (dispatch) => {
      if(typeof onClose !== 'function') {
        console.error("An onClose function must be passed to a confirmation message")
        return
      }
      let newConfirmation = {
        type: 'confirm',
        message,
        onClose,
        options
      }
      dispatch(actions.newMessage(newConfirmation))
    }
  },

  /**
   * Async version of messages.confirm, to be dispatched inline in async functions
   * @param {string} message The message to prompt the user with
   * @param {object} options Optional parameters
   * @param {string} options.confirmText Text to display on the confirm button instead of "Yes"
   * @param {string} options.cancelText Text to display on the cancel button instead of "No"
   * @returns A promise that resolves with true if the user confirms or false if the user cancels. Note that
   *  the promise will still be returned from dispatch(confirmAsync()).
   */
  confirmAsync: (message, options={}) => {
    return (dispatch) => {
      return new Promise((resolve, reject) => {
        let onClose = (result) => {
          resolve(result)
        }
        let newConfirmation = {
          type: 'confirm',
          message,
          onClose,
          options
        }
        dispatch(actions.newMessage(newConfirmation))
      })
    }
  },

  /**
   * Adds a prompt message to the queue of the messages to be displayed
   * @param {string} message The message to prompt the user with
   * @param {function} onSubmit a callback function to call when the user submits the prompt.
   *  The function will be passed the user's input, or null if the user canceled.
   * @param {object} options Optional parameters
   * @param {string} [options.type="string"] The type of value to prompt the user for. An exact listing of prompt types and their unique options can be found at the top of the file.
   *  If the given type is unrecognized, then the type will fallback to the default of "string".
   * @param {function} options.validate If passed, this function will be called with the user's input as an argument.
   *  If the function returns a falsey value, the user's input will not be accepted. Note that certain types have validation
   *  functions of their own that are called before this function.
   * @param {string} options.invalidText For use with options.validate. This is the message that will display when the
   *  user's input fails the validation test (default: "Invalid Input").
   * @param options.initialValue The initial value to use in the prompt (if using a type other than string, the initial value does not need to
   *  be a string)
   */
  prompt: (message, onSubmit, options={}) => {
    return (dispatch) => {
      if(typeof onSubmit !== 'function') {
        console.error("An onSubmit function must be passed to a prompt message")
        return
      }
      // Multiple choice type prompt requires choices to be passed
      if(options.type === "multipleChoice" && !options.choices) {
        console.error("A multiple choice prompt requires choices to be passed in the options argument.")
        return
      }
      // Schedule Date requires a schedule type and viewTime. Interval type also requires an intervalBasis and intervalDuration
      if(options.type === "scheduleDate") {
        if(!options.scheduleType || !options.viewTime) {
          console.error("A schedule date prompt requires a scheduleType and viewTime to be passed in the options argument.")
          return
        }
        if(options.scheduleType === "interval" && (!options.intervalBasis || !options.intervalDuration)) {
          console.error("A schedule date prompt for an interval schedule requires an intervalBasis and an intervalDuration to be passed in the options argument.")
          return
        }
        if(!options.initialValue) {
          options.initialValue = []
        }
      }
      let newConfirmation = {
        type: 'prompt',
        message,
        onSubmit,
        options
      }

      dispatch(actions.newMessage(newConfirmation))
    }
  },

  /**
   * Async version of messages.prompt.
   * @param {string} message The message to prompt the user with
   * @param {object} options Optional parameters. See options for messages.prompt
   * @returns a promise that resolves with the user's input, or null if the user cancels
   */
  promptAsync: (message, options={}) => {
    return (dispatch) => {
      return new Promise((resolve) => {
        let onSubmit = (results) => resolve(results)
        dispatch(messages.prompt(message, onSubmit, options))
      })
    }
  },

}

export const actions = {

  /**
   * Adds a new message to the message queue
   * @param {object} message The message to add to the queue
   */
  newMessage: (message) => ({
    type: NEW_MESSAGE,
    payload: message
  }),

  /**
   * Sets currentMessage to null
   */
  dismissMessage: () => ({
    type: DISMISS_MESSAGE,
  }),

  /**
   * Sets currentMessage to the next message in the queue,
   * unless the queue is empty
   */
  nextMessage: () => {
    return (dispatch, getState) => {
      let {currentMessage, messageQueue} = getState().messages
      if(currentMessage !== null || !messageQueue.length) {
        return
      }
      let newQueue = [...messageQueue]
      let newMessage = newQueue.pop()
      dispatch({
        type: NEXT_MESSAGE,
        payload: {
          currentMessage: newMessage,
          messageQueue: newQueue
        }
      })
    }
  }

}

export const initialState = {
  currentMessage: null, // The currently displayed message
  messageQueue: [],     // An array of messages that have yet to be displayed
}

export default (state=initialState, action) => {
  let {type, payload} = action

  switch(type) {
    case NEW_MESSAGE: {
      return {
        ...state,
        messageQueue: [payload, ...state.messageQueue]
      }
    }
    case DISMISS_MESSAGE: {
      return {
        ...state,
        currentMessage: null
      }
    }
    case NEXT_MESSAGE: {
      let {currentMessage, messageQueue} = payload
      return {
        ...state,
        currentMessage,
        messageQueue
      }
    }
    default:
      return state
  }
}
