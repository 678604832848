// Class representing a schedule

import moment from 'moment'
import ScheduleItem from "helpers/Schedule/ScheduleItem"
import ScheduleBlock from "helpers/Schedule/ScheduleBlock"
import IntervalPeriod from "helpers/IntervalTime/IntervalPeriod"
import ExactPeriod from "helpers/ExactPeriod/ExactPeriod"
import IntervalTime from "helpers/IntervalTime/IntervalTime"
import {fetchFromServer} from 'helpers/net_helpers'
import {parseItemName, parseEndlessDate} from 'helpers/schedule_helpers'
import {deleteIn} from 'helpers/general_helpers'
import {v4 as uuidv4} from 'uuid'
import isEqual from 'lodash.isequal'

const DAYS_OF_THE_WEEK = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday'
]

const scheduleIndexerProxy = {
  get(target, prop) {
    if(target.items && !(prop in target) && prop in target.items) {
      return target.items[prop]
    }
    return target[prop]
  }
}

export default class Schedule {

  constructor(data={}) {
    if(data instanceof Schedule) {
      return this.copyOf(data)
    }
    let proxy = new Proxy(this, scheduleIndexerProxy)
    let {
      type:sched_type='weekly',
      blocks:sched_blocks=[],
      'interval duration':sched_intervalDuration=0,
      'interval base':sched_intervalBasis=0,
      items=[]
    } = data
    if(sched_intervalDuration) {
      sched_intervalDuration = parseInt(sched_intervalDuration, 10)
    }
    if(sched_intervalBasis) {
      sched_intervalBasis = moment(sched_intervalBasis, 'YYYY/MM/DD')
    } else {
      sched_intervalBasis = moment().hours(0).minutes(0).seconds(0).milliseconds(0)
    }

    this.type = sched_type
    this.intervalDuration = sched_intervalDuration
    this.intervalBasis = sched_intervalBasis

    let {schedule, invalid} = parseSchedule(
      items,
      sched_blocks,
      proxy,
    )
    let loadedErrors = validateSchedule(schedule, sched_type)
    let loadedEvents = scanScheduleForEvents(items, sched_type, {...this.intervalDuration, basis: this.intervalBasis})
    let filterTriggers = {}
    if(data.events || data.triggers || data.intervals) {
      filterTriggers = {
        eventTriggers: data.events || data.triggers,
        intervalTriggers: data.intervals
      }
    }

    let defaults = convertJSONDefaultsToInternal(data, sched_type)

    if (data['time slot length'] && data['time slot length'] !== "")
      this.timeSlotLength = parseInt(data['time slot length'])

    this.items = schedule
    this.defaults = defaults
    this.scheduleBlocks = sched_blocks
    this.scheduleErrors = loadedErrors
    this.scheduleMissingFiles = []
    this.eventsList = loadedEvents
    this.invalidItems = invalid
    this.filterTriggers = filterTriggers
    this.versionKey = uuidv4()
    return proxy
  }

  /**
   * Sets this to be a copy of the given Schedule
   * @param {object} schedule the Schedule to copy
   * @returns this
   */
  copyOf(schedule) {
    if(!(schedule instanceof Schedule)) {
      throw new Error(`Trying to make a Schedule into a copy of something that isn't a Schedule: ${schedule}. Schedule.copyOf only accepts other Schedules.`);
    }
    // Copy from block
    Object.keys(schedule).forEach((key) => {
      if(key === "items") {
        let container = this
        this[key] = schedule[key].map((child) => {
          if(child instanceof ScheduleItem) {
            return new ScheduleItem(child, container)
          } else if (child instanceof ScheduleBlock) {
            return new ScheduleBlock(child, container)
          } else {
            return child
          }
        })
      } else if(schedule[key] instanceof Array) {
        this[key] = [...schedule[key]]
      } else if(moment.isMoment(schedule[key])) {
        this[key] = moment(schedule[key])
      } else if(typeof schedule[key] === "object" && schedule[key] !== null) {
        this[key] = {...schedule[key]}
      } else {
        this[key] = schedule[key]
      }
    })
    return new Proxy(this, scheduleIndexerProxy)
  }

  clone() {
    return new Schedule(this)
  }

  get scheduleType() {
    return this.type
  }

  get schedule() {
    return this.items
  }

  set schedule(newSchedule) {
    this.versionKey = uuidv4()
    this.items = newSchedule
  }

  get events() {
    return this.eventsList
  }

  set events(newEvents) {
    this.eventsList = newEvents
    this.updateEvents()
  }

  get errors() {
    return [...this.scheduleErrors, ...this.scheduleMissingFiles]
  }

  nextSiblingOf(key) {
    if(key instanceof ScheduleItem || key instanceof ScheduleBlock) {
      key = key.key
    }
    let container = this.findParentOf(key)
    if(!container) {
      return null
    }
    let currentIndex = container.findIndex((item) => item.key === key)
    if(currentIndex === -1 || currentIndex === (container.length - 1)) {
      return null
    }
    return container[currentIndex + 1]
  }

  previousSiblingOf(key) {
    if(key instanceof ScheduleItem || key instanceof ScheduleBlock) {
      key = key.key
    }
    let container = this.findParentOf(key)
    if(!container) {
      return null
    }
    let currentIndex = container.findIndex((item) => item.key === key)
    if(currentIndex < 1) {
      return null
    }
    return container[currentIndex - 1]
  }

  * iterator() {
    let index = 0
    while(index < this.schedule.length) {
      yield this.schedule[index++]
    }
  }

  [Symbol.iterator]() {
    return this.iterator()
  }

  /**
   * Locates a child by its key and returns it.
   * @param {string} key uuid key of the child to be returned
   * @returns The child ScheduleItem/ScheduleBlock with key, or null if a child with a matching key
   *  is not found
   */
  findByKey(key) {
    for(let item of this) {
      if(item.key === key) {
        return item
      }
      if(item instanceof ScheduleBlock) {
        let child = item.findByKey(key)
        if(child) {
          return child
        }
      }
    }
    return null
  }

  /**
   * Finds the parent of an item using that item's key
   * @param {string} key uuid key of the child item to find the parent of
   * @returns If the item indicated by key is in the schedule and is inside a ScheduleBlock,
   *  then that ScheduleBlock is returned. If it is in the schedule and not in a block, this is returned.
   *  If it is not in this schedule at all, null is returned.
   */
  findParentOf(key) {
    for(let item of this) {
      if(item.key === key) {
        return this
      }
      if(item instanceof ScheduleBlock) {
        let child = item.findByKey(key)
        if(child) {
          return item
        }
      }
    }
    return null
  }

  /**
   * Iterates over each ScheduleItem in this Schedule, including items in ScheduleBlocks
   * @param {Function} fn A function that will act based on each ScheduleItem. fn will be passed the
   *  following arguements: (item, [topIndex, blockIndex]), where item is the current item, topIndex is
   *  the index of the item or its container block within this schedule, and blockIndex is the index of item
   *  within its parent ScheduleBlock or -1 if it is not in a ScheduleBlock.
   */
  forEachItem(fn) {
    let i = 0;
    for(let item of this) {
      if(item instanceof ScheduleBlock || item.type === "block") {
        let j = 0;
        for(let child of item) {
          fn(child, [i, j])
          j++
        }
      } else {
        fn(item, [i, -1])
      }
      i++
    }
  }

  sortByTime() {
    this.schedule = this.schedule.sort((a, b) => a.span.start.diff(b.span.start))
    for(let item of this) {
      if(item instanceof ScheduleBlock) {
        item.sortByTime()
      }
    }
    return this
  }

  addBlock(blockData) {
    if(!blockData) {
      return
    }
    let block;
    if(blockData instanceof ScheduleBlock) {
      block = blockData
    } else {
      block = new ScheduleBlock(blockData, this)
    }
    if(!block || !block.valid) {
      throw new Error(`Tried to add a schedule block to a schedule, but the data given to use for constructing the schedule block produced an invalid block. The data used was: ${JSON.stringify(blockData, null, 2)}`)
    }
    let toReturn = this.clone()
    let tempSchedule = [...toReturn]
    if(tempSchedule.length === 0) {
      tempSchedule = tempSchedule.concat(block)
    } else {
      let firstIndex = tempSchedule.findIndex((schedItem, schedInd) => {
        return block.span.start.valueOf() < schedItem.span.end.valueOf()
      })
      if(firstIndex === -1) {
        firstIndex = tempSchedule.length
      }
      tempSchedule.splice(firstIndex, 0, block)
    }
    toReturn.schedule = tempSchedule
    toReturn = toReturn.blockMunch(block.key)
    return toReturn
  }

  /**
   * Given the name of a schedule block, remove all instances of that block from the schedule
   * @param {string} blockName The name of the block(s) to be removed
   * @param {boolean} [keepChildren=false] If true, keep the children of the block in the schedule. If false, remove the children along with the block.
   * @returns {Schedule} A copy of this with all schedule blocks that have the name blockName removed
   */
  removeBlock(blockName, keepChildren=false) {
    let toReturn = this.clone()
    let chillens = []
    toReturn.schedule = toReturn.schedule.filter((item) => {
      if(item.type === "block" && item.body.label === blockName) {
        if(keepChildren) {
          chillens = [...chillens, ...item.body.contains]
        }
        return false
      }
      return true
    })
    if(keepChildren) {
      toReturn = toReturn.addItems(chillens)
    }
    return toReturn
  }

  /**
   * Given the name of a schedule block, rename all instances of that block to a given new name
   * @param {string} blockName The name of the block(s) to be renamed
   * @param {string} newName The new name for those block(s)
   * @returns {Schedule} A copy of this with all schedule blocks of the given name renamed
   */
  renameBlock(blockName, newName) {
    if(!blockName || !newName) {
      console.warn(`Either the name of the block to rename (${blockName}) or the new name for the block (${newName}) was blank, so not renaming.`)
    }
    let toReturn = this.clone()
    toReturn.schedule = toReturn.schedule.map((item) => {
      if(item.type === "block" && item.body.label === blockName) {
        item.body.label = newName
      }
      return item
    })
    return toReturn
  }

  /**
   * Changes the color of schedule blocks that have a given name
   * @param {string} blockName The name of the block(s) to change the color of
   * @param {string} newColor The color to change the blocks to as a hexadecimal value in the form "#xxxxxx"
   * @returns {Schedule} A copy of this with all schedule blocks of the given name recolored
   */
  changeBlockColor(blockName, newColor) {
    let toReturn = this.clone()
    toReturn.schedule = toReturn.schedule.map((item) => {
      if(item.type === "block" && item.body.label === blockName) {
        item.body.color = newColor
      }
      return item
    })
    return toReturn
  }

  /**
   * Change announce parameter of all schedule blocks that have a given name
   * @param {string} blockName The name of the block(s) to change the announce of
   * @param {string} announce The new value of the announce parameter
   * @returns {Schedule} A copy of this with the announce parameter set to announce for all schedule blocks of the given name
   */
  changeBlockAnnounce(blockName, announce) {
    let toReturn = this.clone()
    toReturn.schedule = toReturn.schedule.map((item) => {
      if(item.type === "block" && item.body.label === blockName) {
        item.announce = announce
      }
      return item
    })
    return toReturn
  }

  addItems(items) {
    if(items.length === 0) {
      return this
    }
    let addingBlocks = false
    items = items.map((item) => {
      if(!(item instanceof ScheduleBlock)) {
        item = new ScheduleItem(item, this)
      } else {
        addingBlocks = true
        item = new ScheduleBlock(item, this)
      }
      return item
    })
    let toReturn = this.clone()
    let tempSchedule = [...toReturn.schedule]
    if(tempSchedule.length === 0) {
      tempSchedule.concat(items)
    }
    let firstTime = items[0].span.start
    let insertInBlock = -1
    if(!addingBlocks) {
      insertInBlock = tempSchedule.findIndex((schedItem, schedInd) => {
        return (schedItem.type === 'block' &&
          schedItem.span.contains(firstTime, {exclusiveEnd: true}))
      })
    }
    if(insertInBlock > -1) {
      tempSchedule[insertInBlock] = tempSchedule[insertInBlock].clone().addItems(items)
    } else {
      items.forEach((item, index) => {
        let beforeIndex = tempSchedule.findIndex((schedItem, schedInd) => {
          if(schedInd + 1 < tempSchedule.length) {
            let period
            if(this.scheduleType === "endless") {
              period = new ExactPeriod(schedItem.span.start, tempSchedule[schedInd + 1].span.start)
            } else {
              period = new IntervalPeriod(schedItem.span.start, tempSchedule[schedInd + 1].span.start)
            }
            return period.contains(item.span.start, {exclusiveEnd: true})
          } else {
            return item.span.start.valueOf() >= schedItem.span.start.valueOf()
          }
        })
        if(beforeIndex === -1) {
          tempSchedule.unshift(item)
        } else if(beforeIndex + 1 < tempSchedule.length) {
          tempSchedule.splice(beforeIndex + 1, 0, item)
        } else {
          tempSchedule.push(item)
        }
      })
    }
    toReturn.schedule = tempSchedule
    return toReturn
  }

  /* NOT USED
  replaceItem(toReplace, toAdd) {
    let replaceItem = this.findByKey(toReplace)
    if(!replaceItem || replaceItem.lock) {
      return this
    }
    let addTime = replaceItem.span.start
    let toReturn = this.removeItem(toReplace)
    toAdd = toAdd.map((item) => {
      item = new ScheduleItem(item, this)
      let duration = item.span.duration()
      item.span.start = addTime
      item.span.end = item.span.start.add(duration)
      addTime = item.span.end
      return item
    })
    toReturn = toReturn.addItems(toAdd)
    return toReturn
  }
  */

  /**
   * Removes an item or block with the given key from the schedule
   * @param {string} key The uuid key of the item to be removed
   * @param {object} [options={}]
   * @param {object} [options.keepChildren=false] If set to true, and key corresponds to a schedule block,
   *  then any children that the schedule block possesses will be re-added to the schedule after the block is removed.
   * @returns {Schedule} A copy of this Schedule with the item matching key removed
   */
  removeItem(key, {keepChildren = false} = {}) {
    let toReturn = this.clone()
    let tempSchedule = [...toReturn.schedule]
    let reAdd = []
    // Remove any missing file errors for the item being removed
    toReturn.scheduleMissingFiles = toReturn.scheduleMissingFiles.filter((err) => err.itemKey !== key)
    tempSchedule = tempSchedule.map((item) => {
      if(item.key === key && !item.lock) {
        if(item instanceof ScheduleBlock) {
          if(keepChildren) {
            reAdd = item.body.contains
          } else {
            let childKeys = item.body.contains.map((item) => item.key)
            toReturn.scheduleMissingFiles = toReturn.scheduleMissingFiles.filter((err) => !childKeys.includes(err.itemKey))
          }
        }
        return null
      }
      if(item instanceof ScheduleBlock) {
        return item.removeItem(key)
      }
      return item
    }).filter((item) => item !== null)
    toReturn.schedule = tempSchedule
    if(reAdd && reAdd.length && keepChildren) {
      return toReturn.addItems(reAdd)
    } else {
      return toReturn
    }
  }

  /**
   * Clears all items from the given schedule day. If this Schedule is a daily schedule, then the whole schedule will be cleared.
   * @param {[Date|Moment|IntervalTime]} day A Date, a Moment, or an IntervalTime indicating which day to clear. Can also pass anything accepted by the IntervalTime constructor
   * @returns {Schedule} A copy of this Schedule with all items on the given day cleared
   */
  clearDay(day) {
    if(this.type === "daily") {
      return this.clearSchedule()
    }
    if(!(day instanceof IntervalTime) && !(day instanceof Date) && !(moment.isMoment(day))) {
      if(this.scheduleType === "endless") {
        day = parseEndlessDate(day)
      } else {
        day = new IntervalTime(day, this.type, {intervalDuration: this.intervalDuration, intervalBasis: this.intervalBasis})
      }
    }
    if(day instanceof IntervalTime) {
      day = day.closestDate(new Date())
    }
    let toReturn = this.clone()
    let removedKeys = []
    toReturn.schedule = toReturn.schedule.filter((item) => {
      let keep = !(item.span.isOnSameDay(day))
      if(!keep) {
        removedKeys.push(item.key)
        if(item instanceof ScheduleBlock) {
          for(let child of item.body.contains) {
            removedKeys.push(child.key)
          }
        }
      }
      return keep
    })
    toReturn.scheduleMissingFiles = toReturn.scheduleMissingFiles.filter((err) => !removedKeys.includes(err.itemKey))
    return toReturn
  }

  /**
   * Clears the schedule of all items
   * @returns A copy of this Schedule with all items cleared
   */
  clearSchedule() {
    let toReturn = this.clone()
    toReturn.schedule = []
    toReturn.scheduleMissingFiles = []
    return toReturn
  }

  /**
   * Clears all items with missing file errors
   * @returns A copy of this Schedule with all items that have missing file errors cleared
   */
  clearMissingFiles() {
    let toReturn = this.clone()
    toReturn.schedule = toReturn.schedule.map((item) => {
      if(toReturn.scheduleMissingFiles.find((err) => err.itemKey === item.key)) {
        return null
      }
      if(item instanceof ScheduleBlock) {
        item.body.contains = item.body.contains.filter((child) => {
          return !(toReturn.scheduleMissingFiles.find((err) => err.itemKey === child.key))
        })
      }
      return item
    }).filter((item) => item !== null)
    for(let err of toReturn.scheduleMissingFiles) {
      if(!err.itemKey.startsWith("DEFAULT:")) {
        continue
      }
      let key = err.itemKey.split(":").slice(1)
      toReturn.defaults = deleteIn(toReturn.defaults, key)
    }
    toReturn.scheduleMissingFiles = []
    return toReturn
  }

  /**
   * Changes the start time of a ScheduleItem or ScheduleBlock, without changing its length. For a ScheduleBlock, all of its children will be
   *  moved as well.
   * @param {string} key The uuid of the item to be moved
   * @param time The new start time of the ScheduleItem/ScheduleBlock; can be an IntervalTime, or anything
   *  that can be passed to the constructor of IntervalTime. For Endless schedules, it can be a moment, or anything that
   *  can be passed to the constructor of moment.
   * @param {Object} [opts={}] Optional arguements
   * @param {boolean} opts.endAtTime Defaults to false. If true, the item will end at the given time instead of starting at it
   * @param {boolean} opts.sort Defaults to true. If false, do not sort this by time after moving the item (for use when iterating over this and
   *  moving multiple children at once)
   * @returns A clone of this with the ScheduleItem moved
   */
  moveItem(key, time, opts={}) {
    let {endAtTime=false, parentSort=true} = opts
    let toReturn = this.clone()
    let toMove = toReturn.findByKey(key)
    if(!toMove) {
      throw new Error(`Tried to move the item with key ${key}, but an item with that key could not be found in the schedule.`)
    }
    if(this.scheduleType === "endless") {
      if(time instanceof IntervalTime) {
        time = time.closestMoment(toMove.span.start)
      } else if (!(moment.isMoment(time))) {
        time = parseEndlessDate(time)
      }
    } else if(!(time instanceof IntervalTime)) {
      time = new IntervalTime(time, toMove.span.period, toMove.span.intervalDuration)
    }
    let duration = toMove.span.duration()
    let newStart, newEnd;
    if(endAtTime) {
      newEnd = time;
      newStart = time.clone().subtract(duration);
    } else {
      newStart = time;
      newEnd = time.clone().add(duration)
    }
    if(toMove.type === "block") {
      let timeDifferential = newStart.diff(toMove.span.start)
      toMove.body.contains = toMove.body.contains.map((child) => {
        if(this.scheduleType === "endless") {
          child.span = new ExactPeriod(
            child.span.start.clone().add(timeDifferential),
            child.span.end.clone().add(timeDifferential))
        } else {
          child.span = new IntervalPeriod(
            child.span.start.add(timeDifferential),
            child.span.end.add(timeDifferential),
            newStart.period,
            newStart.intervalDuration,
            {zeroOk: true}
          )
        }
        return child
      })
    }
    if(this.scheduleType === "endless") {
      toMove.span = new ExactPeriod(newStart, newEnd)
    } else {
      toMove.span = new IntervalPeriod(newStart, newEnd, newStart.period, newStart.intervalDuration, {zeroOk: true})
    }
    if(parentSort) {
      toReturn = toReturn.sortByTime()
    }
    return toReturn
  }

  /**
   * Changes the start and/or end of a ScheduleItem or ScheduleBlock.
   * @param {string} key The uuid of the item or block to change the span of
   * @param {string/Object} [start=null] The new start time of the item or block. Can be an IntervalTime,
   *  or anything accepted by the IntervalTime constructor. If nothing is passed, the start time will remain unchanged
   * @param {string/Object} [end=null] The new end time of the item or block. Can be an IntervalTime,
   *  or anything accepted by the IntervalTime constructor. If nothing is passed, the end time will remain unchanged
   * @param {Object} [opts={}] Optional arguements
   * @param {boolean} [opts.sort=true] If true, will sort Schedule by time after operation.
   * @param {boolean} [opts.blockMunch=false] If true, and the item indicated by key is a ScheduleBlock, then the
   *  ScheduleBlock will munch any ScheduleItems that it overlaps at its new start/end.
   * @returns A copy of this Schedule with the item/block's span changed
   */
  changeItemSpan(key, start=null, end=null, opts={}) {
    let {sort=true, blockMunch=false} = opts
    let toReturn = this.clone()
    let target = toReturn.findByKey(key)
    if(target.lock) {
      return this
    }
    let type = moment.isMoment(target.span.start) ? "endless" : target.span.start.period
    let intervalData = target.span.start.intervalDuration
    if(start) {
      if(type === "endless") {
        start = parseEndlessDate(start)
      } else {
        start = new IntervalTime(start, type, intervalData)
      }
    } else {
      start = target.span.start
    }
    if(end) {
      if(type === "endless") {
        end = parseEndlessDate(end)
      } else {
        end = new IntervalTime(end, type, intervalData)
      }
    } else {
     end = target.span.end
    }
    if(type === "endless") {
      target.span = new ExactPeriod(start, end);
    } else {
      target.span = new IntervalPeriod(start, end);
    }
    if(blockMunch && target instanceof ScheduleBlock) {
      toReturn = toReturn.blockMunch(target.key)
    }
    if(sort) {
      toReturn = toReturn.sortByTime()
    }
    toReturn = toReturn.shoveItem(target.key, 0)
    toReturn = toReturn.shoveItem(target.key, 0, true)
    return toReturn
  }

  /**
   * Adds any items overlapped by specific ScheduleBlock that are not currently children of that block to that
   *  block as children.
   * @param {string} key The uuid of the schedule block that will munch overlapping items
   * @returns A copy of this Schedule with any items overlapping the given block added to that block
   */
  blockMunch(key) {
    let toReturn = this.clone()
    let targetBlock = toReturn.findByKey(key)
    if(!(targetBlock instanceof ScheduleBlock)) {
      console.warn(`Tried to call blockMunch on a schedule item that is not a ScheduleBlock: ${targetBlock}`)
      return this
    }
    let toAdd = []
    let toRemove = []
    for(let item of toReturn) {
      if(item instanceof ScheduleBlock || item.type === "block" || item.lock) {
        continue;
      }
      try {
        if(targetBlock.span.overlaps(item.span)) {
          toAdd.push(item)
          toRemove.push(item.key)
        }
      } catch (err) {
        let itemIndex = toReturn.findIndex((problemChild) => problemChild.key === item.key)
        console.log(itemIndex)
        console.log(item)
      }
    }
    targetBlock = targetBlock.addItems(toAdd)
    toReturn.schedule = toReturn.schedule.filter((item) => !(toRemove.includes(item.key)))
    return toReturn
  }

  /**
   * Closes the gap between two child items, centered on a third "anchor" item that occurs between them
   * @param {string} anchor The key of the item to close the gap around
   * @param {string} start The key of the first chronological item to move
   * @param {string} end The key of the last chronological item to move
   * @returns {Schedule} copy of this with gap closed
   */
  closeGap(anchor, start, end) {
    let toReturn = this.clone()
    let startItem, endItem, anchorItem;
    let anchorParent = toReturn.findParentOf(anchor)
    let startParent = toReturn.findParentOf(start)
    let endParent = toReturn.findParentOf(end)
    if(startParent === anchorParent && anchorParent === endParent) {
      anchorItem = toReturn.findByKey(anchor)
      startItem = toReturn.findByKey(start)
      endItem = toReturn.findByKey(end)
    } else {
      if(anchorParent instanceof ScheduleBlock) {
        anchorItem = anchorParent
      } else {
        anchorItem = toReturn.findByKey(anchor)
      }
      if(startParent instanceof ScheduleBlock) {
        startItem = startParent
      } else {
        startItem = toReturn.findByKey(start)
      }
      if(endParent instanceof ScheduleBlock) {
        endItem = endParent
      } else {
        endItem = toReturn.findByKey(end)
      }
    }
    if(!anchorItem ||
      !startItem ||
      !endItem ||
      startItem.span.start.diff(endItem.span.start) > 0 ||
      startItem.span.start.diff(anchorItem.span.start) > 0 ||
      anchorItem.span.start.diff(endItem.span.start) > 0) {
      return
    }
    // 1. Let currentTime be start time of anchor
    let currentTime = anchorItem.span.start
    // 2. For each item from anchor -1 to start:
    let previous = toReturn.previousSiblingOf(anchorItem)
    let stopPrevious = toReturn.previousSiblingOf(startItem)
    while(previous && (!stopPrevious || previous.key !== stopPrevious.key)) {
    //  - move item to end at currentTime
      if(previous.lock) {
        let err = new Error('Close schedule gap would have moved a locked item, so no items were moved')
        err.type = 'ERR_LOCKED'
        throw err
      }
      toReturn = toReturn.moveItem(previous.key, currentTime, {endAtTime: true, parentSort: false})
    //  - Set currentTime to item's new start time
      currentTime = toReturn.findByKey(previous.key).span.start
      previous = toReturn.previousSiblingOf(previous.key)
    }
    // 3. Let currentTime be end time of anchor
    currentTime = anchorItem.span.end
    // 4. For each item from anchor + 1 to end:
    let next = toReturn.nextSiblingOf(anchorItem)
    let stopNext = toReturn.nextSiblingOf(endItem)
    while(next && (!stopNext || next.key !== stopNext.key)) {
    //  - move item to start at currentTime
      if(next.lock) {
        let err = new Error('Close schedule gap would have moved a locked item, so no items were moved')
        err.type = 'ERR_LOCKED'
        throw err
      }
      toReturn = toReturn.moveItem(next.key, currentTime, {parentSort: false})
    //  - Set currentTime to item's new end time
      currentTime = toReturn.findByKey(next.key).span.end
      next = toReturn.nextSiblingOf(next.key)
    }
    // 5. Sort
    toReturn = toReturn.sortByTime()
    return toReturn
  }

  shoveItem(key, time, backwards=false) {
    // Check to make sure item exists within schedule
    let target = this.findByKey(key)
    if(!target || (time > 0 && target.lock)) {
      return this
    }
    // 1. Copy schedule. Work only on copy
    let toReturn = this.clone()
    target = toReturn.findByKey(key)
    // 2. Add item to a toPush array
    let toPush = [target]
    // 3. Check for any collisions with items that are not in toPush between the start (or end if backwards) of the first item in toPush and
    //  the end (or start if backwards) of an item block of duration equal to the sum of the durations of all items in toPush at time
    //  milliseconds in a direction indicated by backwards.
    // 4. If a colliding item is detected, add it to toPush and repeat from 3.
    if(backwards) {
      let previous = target
      while(toReturn.nextSiblingOf(previous) && toReturn.nextSiblingOf(previous).span.start.valueOf() === target.span.start.valueOf()) {
        previous = toReturn.nextSiblingOf(previous)
      }
      let end = target.span.end
      while(previous) {
        if(previous === target) {
          previous = toReturn.previousSiblingOf(target)
          continue
        }
        if(previous.lock) {
          let err = new Error('Encountered a locked item while pushing, so nothing was pushed.')
          err.type = 'ERR_LOCKED'
          throw err
        }
        let duration = toPush.reduce((accumulator, item) => {
          return accumulator + item.span.duration()
        }, 0)
        let start = end.clone().subtract(duration).subtract(time)
        let checkPeriod = this.scheduleType === "endless" ?
          new ExactPeriod(start, end) :
          new IntervalPeriod(start, end)
        if(checkPeriod.contains(previous.span.end, {exclusiveStart: true})) {
          toPush.push(previous)
          previous = toReturn.previousSiblingOf(previous)
        } else {
          break
        }
      }
    } else {
      let next = target
      while(toReturn.previousSiblingOf(next) && toReturn.previousSiblingOf(next).span.start.valueOf() === target.span.start.valueOf()) {
        next = toReturn.previousSiblingOf(next)
      }
      let start = target.span.start
      while(next) {
        if(next === target) {
          next = toReturn.nextSiblingOf(target)
          continue;
        }
        if(next.lock) {
          let err = new Error('Encountered a locked item while pushing, so nothing was pushed.')
          err.type = 'ERR_LOCKED'
          throw err
        }
        let duration = toPush.reduce((accumulator, item) => {
          return accumulator + item.span.duration()
        }, 0)
        let end = start.clone().add(duration).add(time)
        let checkPeriod = this.scheduleType === "endless" ?
          new ExactPeriod(start, end) :
          new IntervalPeriod(start, end)
        if(checkPeriod.contains(next.span.start, {exclusiveEnd: true})) {
          toPush.push(next)
          next = toReturn.nextSiblingOf(next)
        } else {
          break
        }
      }
    }
    // 5. Once no collisions are detected, set currentTime variable to the start of the block at its new position.
    let currentTime = backwards ?
      target.span.end.clone().subtract(time) :
      target.span.start.clone().add(time)
    // 6. Iterate through toPush and move each item:
    toPush.forEach((item) => {
      toReturn = toReturn.moveItem(item.key, currentTime, {endAtTime: backwards, sort: false})
      currentTime = backwards ? toReturn.findByKey(item.key).span.start : toReturn.findByKey(item.key).span.end
    })
    // 7. Sort
    toReturn = toReturn.sortByTime()
    return toReturn;
  }

  /**
   *  Changes the label of a schedule item with a given key
   *  @param {string} key The uuid key of the item to change the label of
   *  @param {string} newLabel The new value of the label for the given item
   *  @returns {Schedule} A copy of this schedule with the label of the item with the given key changed to newLabel.
   *    If no item with the given key could be found, just returns this.
   */
  changeItemLabel(key, newLabel) {
    let toReturn = this.clone()
    let target = toReturn.findByKey(key)
    if(!target) {
      return this
    }
    target.label = newLabel
    return toReturn
  }

  /**
   * Sets whether a given item is locked or not
   * @param {string} key The uuid key of the item to change the lock status of
   * @param {boolean} lock Whether the item should be locked (true) or unlocked (false)
   * @returns {Schedule} A copy of this schedule with the given item locked or unlocked.
   */
  setItemLock(key, lock) {
    let toReturn = this.clone()
    let target = toReturn.findByKey(key)
    if(!target) {
      return this
    }
    target.lock = lock
    return toReturn
  }

  /**
   * Sets whether a given item will play once or loop
   * @param {string} key The uuid key of the item to change the lock status of
   * @param {boolean} playOnce Whether the item should play once (true) or loop (false)
   * @returns {Schedule} A copy of this schedule with the given item's loop attribute set accordingly.
   */
  setItemLoop(key, loop) {
    let toReturn = this.clone()
    let target = toReturn.findByKey(key)
    if(!target) {
      return this
    }
    target.loop = loop
    return toReturn
  }

  /**
   * Sets or removes the scte35 event for a schedule item
   * @param {string} key The uuid key of the item to change the scte-35 event data of
   * @param {string} val A comma seperated list of key=value string pairs, or an empty string.
   *  An list of pairs will set the scte35 event to that list; an empty string will remove the scte35 event
   * @returns {Schedule} A copy of this schedule with the given item's scte35 event set accordingly.
   */
  setSCTE35(key, val) {
    let toReturn = this.clone()
    let target = toReturn.findByKey(key)
    if(!target) {
      return this
    }
    if(!val && target.scte35) {
      delete target.scte35
    } else if (val.match(/(?:[^=\n]+=[^=,\n]*)(?:,[^=\n]+=[^=,\n]*)*/)) {
      target.scte35 = val
    } else {
      throw new Error(`Improperly formatted scte-35 string! Trying to set scte-35 event on item with id ${key} to ${val}`)
    }
    return toReturn
  }

  /**
   * Checks self for errors and updates this.scheduleErrors
   * @params {object} opts Optional arguements
   * @params {boolean} opts.noMillis If true, overlaps of less than one second will not be considered errors. If false,
   *  then any overlap will be considered an error even if it is less than one second. Defaults to true
   * @params {array} opts.changed An array of items that changed. Validation will be restricted to only those changed items
   *  Passing an empty array will result in validation being skipped.
   * @returns this
   */
  validate(opts={}) {
    if(!(opts.changed instanceof Array) || opts.changed.length !== 0) {
      this.scheduleErrors = validateSchedule(this.schedule, this.type, null, opts)
    }
    return this
  }

  /**
   * Checks children for errors with files not existing and updates this.scheduleMissingFiles
   * @returns {Schedule} this
   */
  async checkFilesExist() {
    let errors = await this.getFileExistence()
    return this.setMissingFileErrors(errors)
  }

  async getFileExistence() {
    let errors = []
    let toCheck = {}
    this.forEachItem((item) => {
      if(item.type === "item") {
        if(!toCheck[item.originalItem]) {
          toCheck[item.originalItem] = []
        }
        toCheck[item.originalItem].push(item.key)
      }
    })
    // Check Defaults as well
    for(let [key, item] of Object.entries(this.defaults)) {
      if(item.originalItem) {
        let fpath = item.originalItem
        if(!fpath.startsWith("/")) {
          fpath = "/" + fpath
        }
        if(!toCheck[fpath]) {
          toCheck[fpath] = []
        }
        toCheck[fpath].push(`DEFAULT:${key}`)
      } else if(typeof item === "object") {
        for(let [childKey, childItem] of Object.entries(item)) {
          if(childItem.originalItem) {
            let fpath = childItem.originalItem
            if(!fpath.startsWith("/")) {
              fpath = "/" + fpath
            }
            if(!toCheck[fpath]) {
              toCheck[fpath] = []
            }
            toCheck[fpath].push(`DEFAULT:${key}:${childKey}`)
          }
        }
      }
    }
    if(Object.keys(toCheck).length) {
      try {
        let res = await fetchFromServer("/v2/files/identify", {
          method: "POST",
          body: Object.keys(toCheck).join("\n")
        })
        if(!res.ok) {
          let err = await res.text();
          console.error(err);
        } else {
          let fileExistence = await res.json();
          for(let [file, keys] of Object.entries(toCheck)) {
            if(!fileExistence[file]) {
              errors.push(...keys.map((key) => ({type:'FILE_DOES_NOT_EXIST', itemKey: key})))
            }
          }
          /*
          this.forEachItem((item, index) => {
            if(item.type === "item" && !fileExistence[item.originalItem]) {
              errors.push({type:'FILE_DOES_NOT_EXIST', itemKey: item.key})
            }
          })
          */
        }
      } catch (err) {
        console.error(err)
      }
    }
    return errors
  }

  setMissingFileErrors(errors) {
    // Check if the errors actually changed. If not, don't bother
    if(isEqual(this.scheduleMissingFiles, errors)) {
      return this
    }
    let toReturn = this.clone()
    toReturn.scheduleMissingFiles = errors
    return toReturn
  }

  async checkEventsExist() {
    let eventExistence = await this.getEventExistence()
    return this.setEventExistence(eventExistence)
  }

  async getEventExistence() {
    let toCheck = []
    let fileExistence = {}
    if(!this.eventsList) {
      return fileExistence
    }
    for(let evt of Object.values(this.eventsList)) {
      if(evt.name) {
        toCheck.push(evt.name)
      }
    }
    if(toCheck.length) {
      try {
        let res = await fetchFromServer("/v2/events/identify", {
          method: "POST",
          body: toCheck.join("\n")
        })
        if(!res.ok) {
          let err = await res.text();
          console.error(err);
        } else {
          fileExistence = await res.json();
        }
      } catch (err) {
        console.error(err)
      }
    }
    return fileExistence
  }

  setEventExistence(fileExistence) {
    let newEventsList = this.eventsList
    for(let [key, evt] of Object.entries(newEventsList)) {
      let assigned = fileExistence[evt.name]
      newEventsList[key] = {
        ...evt,
        assigned
      }
    }
    let toReturn = this.clone()
    toReturn.eventsList = newEventsList
    return toReturn
  }

  /**
   * Updates event ScheduleItems based on changes to the eventsList
   * @returns A copy of this Schedule with the event ScheduleItems updated
   */
  updateEvents() {
    let toReturn = this.clone()
    toReturn.schedule = toReturn.schedule.map((item) => {
      if(item.type === 'block') {
        return item.updateEvents(toReturn.eventsList)
      } else if (item.type === 'event') {
        let match = toReturn.eventsList[item.guid]
        if(match) {
          let end = item.span.start.clone().add(Math.floor(match.duration.asMilliseconds()))
          let newItem = item.clone()
          newItem.body.label = match.name
          if(this.scheduleType === "endless") {
            newItem.span = new ExactPeriod(item.span.start, end)
          } else {
            newItem.span = new IntervalPeriod(item.span.start, end, toReturn.type, undefined, {zeroOk: true})
          }
          newItem.originalItem = `/mnt/main/Events/Play/${match.name}`
          newItem.assigned = {}
          if (match.toCopy) {
            newItem.assigned = {...newItem.assigned, toCopy: match.toCopy}
          }
          if(match.assigned) {
            newItem.assigned = {...newItem.assigned, ...match.assigned}
          }
          return newItem
        } else {
          return null
        }
      }
      return item
    }).filter((item) => item !== null)
    return toReturn
  }

  /**
   * Moves all toCopy items assigned to events to the assigned/copying slot. Invoke this after starting the copy operations for those items.
   * @returns A copy of this Schedule with the eventsList and event ScheduleItems updated.
   */
  queueAssignedEventItems() {
    let toReturn = this.clone()
    let newEventsList = {...toReturn.eventsList}
    Object.entries(toReturn.eventsList).forEach(([key, val]) => {
      let evntData = {...val}
      if(evntData.toCopy) {
        let uploadFilepath = evntData.toCopy[evntData.toCopy.length - 1]
        evntData.assigned.copying = uploadFilepath
        delete evntData.toCopy
      }
      newEventsList[key] = evntData
    })
    toReturn.eventsList = newEventsList
    return toReturn.updateEvents()
  }

  /**
   * Copies all items from one day of the schedule to a number of other specific days, completely replacing the contents
   *  of those days
   * @param {Moment|IntervalTime} sourceDay An interval time indicating the day to replicate from. For endless schedules,
   *  it is instead a Moment.
   * @param {array} replicateTo An array of interval times indicating which days to replicate to. For endless schedules,
   *  it is instead an array of Moments.
   * @param {boolean} [includeBlocks=false] If true, copy schedule blocks as well as items to the target
   *  day(s). Otherwise, only copy items (including the children of blocks), but not the blocks themselves.
   * @returns A copy of this Schedule with the items (and possibly blocks) from the sourceDay copied to
   *  each day in replicateTo, with any previous contents of the days in replicateTo being overridden.
   */
  replicateScheduleDay(sourceDay, replicateTo, includeBlocks=false) {
    if(this.type === "daily") {
      return this
    }
    let sourceDate
    if(this.scheduleType === "endless") {
      sourceDate = sourceDay
    } else {
      sourceDate = sourceDay.closestDate(new Date())
    }
    // Get the items that are going to be copied from the source day as an array
    let itemsToReplicate = []
    this.schedule.filter((item) => {
      return item.span.isOnSameDay(sourceDate)
    }).forEach((item) => {
      if(item instanceof ScheduleBlock && !includeBlocks) {
        itemsToReplicate = itemsToReplicate.concat(item.body.contains)
      } else {
        itemsToReplicate.push(item)
      }
    })
    let toReturn = this
    sourceDay = sourceDay.clone().set("hour", 0).set("minute", 0).set("second", 0).set("millisecond", 0)
    for(let day of replicateTo) {
      // Clear each of the target days
      toReturn = toReturn.clearDay(day)
      let targetDay = day.clone().set("hour", 0).set("minute", 0).set("second", 0).set("millisecond", 0)
      let dayDifference = targetDay.diff(sourceDay)
      // Add the source items to each of the target days
      let addToDay = itemsToReplicate.map((item) => {
        let addItem = item.clone({newKey: true})
        addItem.span = addItem.span.add(dayDifference)
        if(addItem.type === "block") {
          addItem.body.contains = addItem.body.contains.map((child) => {
            child.span = child.span.add(dayDifference)
            return child
          })
        }
        return addItem
      })
      toReturn = toReturn.addItems(addToDay)
    }
    return toReturn
  }

  /**
   * Replicates one or more items to given times. If multiple items are given, then the earliest item will start
   *  at each of the times given and the other items will be placed relative to that first item
   * @param {array} toReplicate A list of keys of items to be replicated
   * @param {array} times A list of IntervalTimes to replicate the items to.
   * @returns {Schedule} A copy of this with the given items replicated to the given times.
   */
  replicate(toReplicate, times) {
    if(times.length === 0) {
      console.warn("Trying to replicate, but the array of times to replicate to is empty")
      return this
    }
    // Get the items that are going to be replicated, and make an array of them in order of their start times
    let itemsToReplicate = toReplicate.map((key) => this.findByKey(key))
      .filter((item) => item !== null)
      .sort((a, b) => a.span.start.diff(b.span.start))
    if(!itemsToReplicate.length) {
      console.warn("Trying to replicate, but there are no valid items to replicate")
      return this
    }
    let toAdd = []
    for(let time of times) {
      if(this.scheduleType === "endless" && !(moment.isMoment(time))) {
        time = parseEndlessDate(time)
      } else if(this.scheduleType !== "endless" && !(time instanceof IntervalTime)) {
        time = new IntervalTime(time, this.type, {basis: this.intervalBasis, days: this.intervalPeriod})
      }
      let timeDifference = itemsToReplicate[0].span.start.diff(time)
      for(let item of itemsToReplicate) {
        let addItem = item.clone({newKey: true})
        addItem.span = addItem.span.subtract(timeDifference)
        if(addItem.type === "block") {
          addItem.body.contains = addItem.body.contains.map((child) => {
            child.span = child.span.subtract(timeDifference)
            return child
          })
        }
        toAdd.push(addItem)
      }
    }
    return this.addItems(toAdd).sortByTime()
  }

  /**
   *  Replicates one or more child items of a given block to other blocks of the same name
   *  @param {string} blockKey The string key of the block to replicate the children of
   *  @param {array} itemKeys An array of string keys of children of the given block to be replicate
   *  @param {string} direction The direction to replicate the items in; either "forward", "backward", or "both". Defaults to "both"
   *  @returns this
   */
  replicateItemsToBlocks(blockKey, itemKeys, direction="both") {
    let block = this.findByKey(blockKey)
    if(!block) {
      throw new Error(`Could not find schedule block with id ${blockKey}`)
    }
    // Get array of items to replicate
    let toReplicate = itemKeys.map((id) => {
      let item = this.findByKey(id)
      if(!item) {
        return {
          item: null
        }
      }
      let fromStart = item.span.start.diff(block.span.start)
      return {
        item,
        fromStart
      }
    }).filter(({item, fromStart}) => {
      let itemContainer = this.findParentOf(item.key)
      return (itemContainer && itemContainer instanceof ScheduleBlock && itemContainer.key === blockKey)
    })
    // Replicate
    let ahead = false
    let tempSchedule = this.schedule.map((childBlock) => {
      // Ignore all children except blocks with the same name as the given block
      if(!(childBlock instanceof ScheduleBlock) || childBlock.body.label !== block.body.label) {
        return childBlock
      }
      // When encountering the block given by blockKey, set ahead to true and then skip it
      if(childBlock === block) {
        ahead = true
        return childBlock
      }
      if((direction === "both") ||
        (direction === "forward" && ahead) ||
        (direction === "backward" && !ahead)) {
        let toAdd = toReplicate.map(({item, fromStart}) => {
          // New clone which is coexisting with the original, so needs new uuid
          let addItem = item.clone({newKey: true})
          // Move to time of new block
          let dur = addItem.span.duration()
          let newStart = childBlock.span.start.clone().add(fromStart)
          let newEnd = newStart.add(dur)
          if(this.scheduleType === "endless") {
            addItem.span = new ExactPeriod(newStart, newEnd)
          } else {
            addItem.span = new IntervalPeriod(newStart, newEnd, childBlock.span.type, childBlock.span.intervalDuration, {zeroOk: true})
          }
          return addItem
        })
        childBlock.addItems(toAdd)
        return childBlock
      }
      return childBlock
    })
    this.schedule = tempSchedule
    return this
  }

  /**
   * Copies all properties of a ScheduleBlock to other blocks of the same name
   * NOTE: Despite similarities (such as both targeting all blocks that share the same name as the given block), copyScheduleBlock and replicateItemsToBlock are very different functions.
   * copyScheduleBlock completely replaces all parameters (except start time) and children of all targeted blocks with the parameters and children of the given block.
   * replicateItemsToBlock only copies some or all children of the given block to the targeted block, without changing any other parameters of the targeted block or removing any of the
   * targeted blocks' other children.
   * @param {string} blockKey The key of the block to copy from
   * @param {string} [direction="both"] The direction to copy in; either "both", "forward", or "backward".
   *  For "forward", only blocks that occur after the given block will be copied to. For "backward", only
   *  blocks that occur before the given block will be copied to. For "both" all blocks before and after
   *  will be copied to. (In any case, only blocks with the same name will be copied to).
   * @returns {Schedule} A copy of this with the schedule block replicated
   */
  copyScheduleBlock(blockKey, direction="both") {
    let toReturn = this.clone()
    let block = toReturn.findByKey(blockKey)
    if(!block || block.type !== 'block') {
      return this
    }
    let blockDuration = block.span.end.diff(block.span.start)
    let blockContents = block.body.contains.map((child, childInd) => {
      return {
        item: child,
        relativeStart: child.span.start.diff(block.span.start),
        relativeEnd: child.span.end.diff(block.span.start)
      }
    })
    let ahead = false
    let tempSched = toReturn.schedule.map((item) => {
      if(item.key === blockKey) {
        ahead = true
        return item
      }
      if(item.type === 'block' && item.body.label === block.body.label &&
       ((direction === 'both') ||
        (direction === 'forward' && ahead) ||
        (direction === 'backward' && !ahead))) {
        let toReturn = item.clone({newKey: true})
        if(this.scheduleType === "endless") {
          toReturn.span = new ExactPeriod(
            item.span.start,
            item.span.start.clone().add(blockDuration),
          )
        } else {
          toReturn.span = new IntervalPeriod(
            item.span.start,
            item.span.start.add(blockDuration),
            item.span.period
          )
        }
        toReturn.body.contains = blockContents.map((child) => {
          let newChild = child.item.clone({newKey: true})
          if(this.scheduleType === "endless") {
            newChild.span = new ExactPeriod(
              item.span.start.clone().add(child.relativeStart),
              item.span.start.clone().add(child.relativeEnd),
            )
          } else {
            newChild.span = new IntervalPeriod(
              item.span.start.add(child.relativeStart),
              item.span.start.add(child.relativeEnd),
              item.span.period
            )
          }
          return newChild
        })
        return toReturn
      } else {
        return item
      }
    })
    toReturn.schedule = tempSched
    return toReturn
  }

  /**
   * Changes a ScheduleBlock's start and end so that it starts at the beginning of its first child and ends at the end of its last child
   * @param {string} key The uuid key of the block to fit
   * @param {object} opts Optional arguements
   * @param {boolean} opts.dontContract If set to true, then the block will only expand to cover children that are outside of its start and end.
   *  If the first child begins after this block's beginning, then the beginning of the block will not change. If the last child ends before this
   *  block's end, then the end of the block will not change. Defaults to false.
   * @returns a clone of this schedule with the schedule block fit to its contents
   */
  fitBlockToContents(key, opts={}) {
    let {dontContract=false} = opts
    let thisBlock = this.findByKey(key)
    if(!thisBlock || !(thisBlock instanceof ScheduleBlock)) {
      return this
    }
    let toReturn = this.clone()
    let block = toReturn.findByKey(key)
    let firstChild = block.body.contains[0]
    let lastChild = block.body.contains[block.body.contains.length - 1]
    if(!firstChild || !lastChild) {
      return this
    }
    let startMove = block.span.start.diff(firstChild.span.start)
    if(dontContract && startMove < 0) {
      startMove = 0
    }
    let endMove = lastChild.span.end.diff(block.span.end)
    if(dontContract && endMove < 0) {
      endMove = 0
    }
    if(startMove || endMove) {
      toReturn = toReturn.changeItemSpan(key, block.span.start.subtract(startMove), block.span.end.add(endMove))
    }
    return toReturn
  }

  /**
   * Sets a given default item
   * @param {string} defType The type of default to set; either "global", "daily", "overlayOne" or "overlayTwo"
   * @param {[Moment|IntervalTime]} defTime The day to set the default for. Required for all default types except "global"; for "global" it can be omitted.
   * @param {object} item An object representing a library item, including its metadata
   * @returns {Schedule} A copy of this with the default item set accordingly
   */
  setDefault(defType, defTime=null, item) {
    let [defaultType, defToSet] = defaultToSet(this.type, defType, defTime)
    let toReturn = this.clone()
    let newDefaults = {...toReturn.defaults}
    let setItem = {
      originalItem: item.fpath,
      label: parseItemName(item.fpath),
      duration: item.metadata.duration,
    }
    if(item.metadata.in) {
      setItem.in = item.metadata.in
    }
    if(item.metadata.out) {
      setItem.out = item.metadata.out
    }
    if(item.metadata.offset) {
      setItem.offset = item.metadata.offset
    }
    if(item.selectedAssociation && item.associations) {
      let assoc = item.associations.find( (asobj) => { if (typeof asobj === 'object') { if (asobj.id === item.selectedAssociation) return true } return false })
      if (assoc) {
        setItem.association = assoc.id
        setItem.associationName = assoc.name
      }
    }
    if(setItem.association) {
      setItem.label = `${setItem.label} [${setItem.association}]`
    }
    if(defaultType === 'global') {
      newDefaults.global = setItem
      toReturn.scheduleMissingFiles = toReturn.scheduleMissingFiles.filter((err) => err.itemKey !== "DEFAULT:global")
    } else {
      if(!newDefaults[defToSet]) {
        newDefaults[defToSet] = {}
      }
      newDefaults[defToSet] = {
        ...newDefaults[defToSet],
        [defaultType]: setItem
      }
      toReturn.scheduleMissingFiles = toReturn.scheduleMissingFiles.filter((err) => err.itemKey !== `DEFAULT:${defToSet}:${defaultType}`)
    }
    toReturn.defaults = newDefaults
    return toReturn
  }

  /**
   * Removes the given default item
   * @param {string} defType The type of default to remove; either "global", "daily", "overlayOne" or "overlayTwo"
   * @param {[Moment|IntervalTime]} defTime The day to remove the default for. Required for all default types except "global"; for "global" it can be omitted.
   * @returns {Schedule} A copy of this with the given default item removed
   */
  removeDefault(defType, defTime) {
    let [defaultType, defToSet] = defaultToSet(this.type, defType, defTime)
    let toReturn = this.clone()
    let newDefaults = {...toReturn.defaults}
    if(defaultType === 'global' && newDefaults.global) {
      delete newDefaults.global
      toReturn.scheduleMissingFiles = toReturn.scheduleMissingFiles.filter((err) => err.itemKey !== "DEFAULT:global")
    } else {
      if(newDefaults[defToSet] && newDefaults[defToSet][defaultType]) {
        let defaultsToSet = {...newDefaults[defToSet]}
        delete defaultsToSet[defaultType]
        newDefaults[defToSet] = defaultsToSet
      }
      toReturn.scheduleMissingFiles = toReturn.scheduleMissingFiles.filter((err) => err.itemKey !== `DEFAULT:${defToSet}:${defaultType}`)
    }
    toReturn.defaults = newDefaults
    return toReturn
  }

  /**
   * Sets the interval duration and/or basis of an interval schedule
   * @param {object} newInterval Object containing new interval information. Interval duration can be updated with the key "duration" as a number of days.
   *  If a "duration" key is not included, then the interval duration will not be changed.
   *  Can also include a "basis" which is either a Date or a Moment to update the interval basis. If a basis is not included, then the basis will not be changed.
   * @returns {Schedule} If this is an interval schedule, then a copy of this Schedule will be returned with the interval updated. If this is not
   *  an interval schedule, then nothing will be changed and this will be returned.
   */
  setInterval(newInterval) {
    if(this.type !== "interval") {
      console.warn("Tried to set the interval on a non-interval schedule; this does not do anything.")
      return this
    }
    if(!newInterval.duration && !newInterval.basis) {
      return this
    }
    let toReturn = this.clone()
    if(newInterval.duration) {
      newInterval.days = newInterval.duration
    }
    for(let item of toReturn) {
      if(newInterval.days) {
        item.span = item.span.setIntervalDuration(newInterval)
      } else if (newInterval.basis) {
        item.span = item.span.setIntervalBasis(newInterval.basis)
      }
      if(item instanceof ScheduleBlock || item.type === "block") {
        for(let child of item) {
          if(newInterval.days) {
            child.span = child.span.setIntervalDuration(newInterval)
          } else if (newInterval.basis) {
            child.span = child.span.setIntervalBasis(newInterval.basis)
          }
        }
      }
    }
    if(newInterval.days) {
      toReturn.intervalDuration = newInterval.days
    }
    if(newInterval.basis) {
      toReturn.intervalBasis = newInterval.basis
    }
    return toReturn
  }

  /**
   * Serializes this schedule to a JSON object for saving to a schedule file
   * @returns {Object} An object representation of this schedule
   */
  serializeToObject() {
    let JSONSchedule = this.serializeDefaultsToJSON()
    JSONSchedule.type = this.type
    JSONSchedule.blocks = []
    JSONSchedule.items = []
    this.schedule.forEach((item, index) => {
      switch(item.type) {
        case 'item':
        case 'event':
          JSONSchedule.items.push(item.serializeToObject())
          break
        case 'block':
          JSONSchedule.items = JSONSchedule.items.concat(item.serializeChildrenToObject())
          JSONSchedule.blocks.push(item.serializeToObject())
          break
        case 'empty':
          break
        default:
          throw new Error(`The item at position ${index} in the schedule had an unknown type: ${item.type}`)
      }
    })
    if(this.type === 'interval') {
      JSONSchedule['interval duration'] = "" + this.intervalDuration
      JSONSchedule['interval base'] = this.intervalBasis.format('YYYY/M/D')
    }
    JSONSchedule['text encoding'] = 'UTF-8'
    JSONSchedule['schedule format version'] = '5.0.0.4 2021/01/15'
    JSONSchedule['filter script'] = ''
    JSONSchedule.scrolltime = '12:00 am'
    if(this.filterTriggers.eventTriggers) {
      JSONSchedule['triggers'] = this.filterTriggers.eventTriggers
    }
    if(this.filterTriggers.intervalTriggers) {
      JSONSchedule['intervals'] = this.filterTriggers.intervalTriggers
    }
    return JSONSchedule
  }

  /**
   * Converts this schedule's defaults to a JSON object for saving to a schedule file
   * @returns {Object} this Schedule's defaults as a JSON object
   */
  serializeDefaultsToJSON() {
    let toReturn = {
      'global default': ''
    }
    let defaultsKey = getDefaultsSectionName(this.type)
    toReturn[defaultsKey] = {}
    Object.entries(this.defaults).forEach(([key, value]) => {
      if(key === 'global' && this.type !== 'daily') {
        let item = value.originalItem
        if(!item.startsWith('/')) {
          item = `/${item}`
        }
        toReturn['global default'] = item
        let obj = parseDefaultsEntryToJSON('global', { global: value })
        if (obj !== undefined) toReturn['global default section'] = obj
        return
      }
      let indicator = parseTimeIndicator(key, this.type)
      if(indicator === null) {
        return
      }
      let parsedDefault = parseDefaultsEntryToJSON(indicator, value)
      toReturn[defaultsKey] = {...toReturn[defaultsKey], ...parsedDefault}
    })
    return toReturn
  }
}

export const parseSchedule = (schedule, blocks, container) => {
  let invalidItems = []
  // Create array of display schedule items
  let returnSched = schedule.map((item) => new ScheduleItem(item, container))
    .filter(item => item.valid)
  // Create array of empty display schedule blocks
  let schedBlocks = blocks.map((block) => new ScheduleBlock(block, container))
    .filter(block => block.valid)
  // Combine schedule and schedule blocks
  let toReturn = combineScheduleAndBlocks(returnSched, schedBlocks)
  return {schedule: toReturn, invalid: invalidItems}
}

/**
 * Helper function that combines a list of schedule blocks and a list of schedule items together, then sorts them by start time
 * @param {array} schedule An array of schedule items. This array is mutated by this function, and should not be used afterwards.
 * @param {array} blocks An array of schedule blocks. This array is mutated by this function, and should not be used afterwards.
 * @returns An array of both schedule items and blocks sorted by start time. Items which fall within a schedule block will be moved from schedule to the block's body.contains param.
 */
const combineScheduleAndBlocks = (schedule, blocks) => {
  schedule = schedule.filter((item, index) => {
    // These two if statements are set up separately like this for flow control purposes
    if(!item) {
      return false
    }
    if(!item.block) {
      return true
    }
    let containBlock = blocks.find((block) => (
      item.block === block.body.label &&
      block.span.overlaps(item.span)))
    if(containBlock) {
      containBlock.body.contains.push(new ScheduleItem(item, containBlock))
      return false
    }
    return true
  })
  return blocks.concat(schedule).sort((a, b) => {return a.span.start.diff(b.span.start)})
}

/**
 * Checks a schedule for errors and returns an array of schedule errors
 * @param {array} schedule An array of schedule items or blocks
 * @param {string} type The type of schedule
 * @param {object} block Object containing time span and index of the current block if schedule is a block.
 *  If it is a block, it should not contain other blocks and other blocks encountered are a collision.
 * @param {object} opts Optional arguements
 * @params {boolean} opts.noMillis If true, overlaps of less than one second will not be considered errors. If false,
 *  then any overlap will be considered an error even if it is less than one second. Defaults to true
 * @params {array} opts.changed An array of items that changed. Validation will be restricted to only those changed items
 *  Passing an empty array will result in validation being skipped.
 * @returns an array of schedule error objects, which have the following properties:
 *    {number} index The index of the item/block encountering an error
 *    {string} type The type of error encountered. Will be one of the following:
 *      FRONT_END: The item's end occurs after the next item's start
 *      REAR_END: The item's start occurs before any previous item's end
 *      TOTAL: The item occurs entirely within another item
 *      FRONT_DROP_OFF: The item's end occurs after the end of the schedule/block it is in
 *      REAR_DROP_OFF: The item's start occurs before the beginning of the schedule/block it is in
 *      TOTAL_DROP_OFF: The item's start and end enclose its container's start and end
 *      RECURSIVE_BLOCK: A schedule block is inside another schedule block
 *    {number} child Index of item within its parent block, if the error occurs in a block
 */
export const validateSchedule = (schedule, type, block=null, opts={}) => {
  let {noMillis=true, changed=null, added={}} = opts
  let errors = []
  // Factoring out repetitive code
  let addError = (err) => {
    if(added[err.itemKey] && added[err.itemKey][err.type]) {
      return
    }
    if(!added[err.itemKey]) {
      added[err.itemKey] = {}
    }
    added[err.itemKey][err.type] = true
    errors.push(err)
  }
  schedule.forEach((item, index) => {
    if(item.type === 'empty') {
      return
    }
    if(changed instanceof Array && (!changed.includes(item.key) || !(block && changed.includes(block.key)))) {
      return
    }
    // If validating a block's contents, check for schedule drop-off (see above for which drop_off type is which)
    if(block !== null) {
      let container = block.span
      let overlaps = container.overlaps(item.span, {giveOverlapType: true, containsOk: true, exclusiveStart: true, exclusiveEnd: true, noMillis})
      if(overlaps) {
        let err = {
          itemKey: item.key,
          frontSeverity: overlaps !== "BACK_OVERLAP" ?
            item.span.end.diff(container.end) :
            0,
          rearSeverity: overlaps !== "FRONT_OVERLAP" ?
            container.start.diff(item.span.start) :
            0
        }
        if(overlaps === "FRONT_OVERLAP") {
          err.type = "FRONT_DROP_OFF"
        } else if(overlaps === "BACK_OVERLAP") {
          err.type = "REAR_DROP_OFF"
        } else {
          err.type = "TOTAL_DROP_OFF"
        }
        addError(err)
      }
    }
    // If there are items after this one in the schedule, check for overlapping items
    let i = 1
    while(index + i < schedule.length) {
      let checking = schedule[index + i]
      if(checking.type === 'empty') {
        i = i + 1
        continue
      }
      let overlaps = item.span.overlaps(checking.span, {giveOverlapType: true, noMillis})
      if(overlaps) {
        if(overlaps === "BACK_OVERLAP") {
          addError({type:'REAR_END', itemKey: item.key})
          addError({type:'FRONT_END', itemKey: checking.key})
          break
        } else if(overlaps === "CONTAINS" || overlaps === "CONTAINED_BY") {
          addError({type:'TOTAL', itemKey: item.key})
          addError({type:'TOTAL', itemKey: checking.key})
          i = i + 1
          continue
        } else {
          addError({type:'FRONT_END', itemKey: item.key})
          addError({type:'REAR_END', itemKey: checking.key})
          break
        }
      }
      break
    }
    i = 1
    while(index - i >= 0) {
      let checking = schedule[index - i]
      if(checking.type === 'empty') {
        i = i + 1
        continue
      }
      let overlaps = item.span.overlaps(checking.span, {giveOverlapType: true, noMillis})
      if(overlaps) {
        if(overlaps === "BACK_OVERLAP") {
          addError({type:'REAR_END', itemKey: item.key})
          addError({type:'FRONT_END', itemKey: checking.key})
          break
        } else if(overlaps === "CONTAINS" || overlaps === "CONTAINED_BY") {
          addError({type:'TOTAL', itemKey: item.key})
          addError({type:'TOTAL', itemKey: checking.key})
          i = i + 1
          continue
        } else {
          addError({type:'FRONT_END', itemKey: item.key})
          addError({type:'REAR_END', itemKey: checking.key})
          break
        }
      }
      break
    }
    // If the item is a block and it is inside another block, mark it as a recursive block. Otherwise, validate its contents
    if(item.type === 'block') {
      if(block !== null) {
        addError({type:'RECURSIVE_BLOCK', itemKey: item.key})
      } else {
        let errorsInBlock = validateSchedule(item.body.contains, 'block', {span: item.span, index, added}, opts)
        for(let err of errorsInBlock) {
          addError(err)
        }
      }
    }
  })
  return errors
}

/**
 * Scans a schedule file for events
 * @param {array} schedule A schedule in local json format
 * @param {string} type The schedule type
 * @param {object} intervalData Object containing interval information for events in interval schedules.
 * @returns an object where the keys are the ids of events in the schedule and the values
 *  are the those events' properties
 */
export const scanScheduleForEvents = (schedule, type, intervalData={}) => {
  let eventInfoList = {}
  schedule.forEach((item) => {
    if(item['event id']) {
      if(!eventInfoList[item.guid]) {
        let duration;
        if(item['item duration']) {
          duration = moment.duration(parseInt(item['item duration'], 10) * 1000)
        } else {
          let span = type === "endless" ?
            new ExactPeriod(item.start, item.end) :
            new IntervalPeriod(item.start, item.end, type, intervalData)
          duration = moment.duration(span.duration())
        }
        eventInfoList[item.guid] = {
          name: item['event id'],
          duration,
          uuid: item.guid,
          assigned: (item['error status'] !== "ENOENT")
        }
      }
    }
  })
  return eventInfoList
}

const convertJSONDefaultsToInternal = (data, scheduleType) => {
  let toReturn = {}
  let defaults = data[getDefaultsSectionName(scheduleType)]
  if(defaults) {
    Object.entries(defaults).forEach(([key, value]) => {
      let [type, indicator] = key.split(', ')
      // Daily default
      if(!indicator) {
        indicator = type
        type = 'daily'
      }
      let defaultSection = parseJSONDefaultIndicator(indicator, scheduleType)
      if(defaultSection === undefined || defaultSection < 0) { /* NTS: defaultSection can be === 0 which is also !defaultSection */
        return
      }
      let [defaultKey, defaultValue] = parseJSONDefaultEntry(type, value)
      // Just in case we run into a default entry that is bad (no value, for example)
      if(!defaultKey || !defaultValue) {
        return
      }
      if(!toReturn[defaultSection]) {
        toReturn[defaultSection] = {}
      }
      if(!toReturn[defaultSection][defaultKey]) {
        toReturn[defaultSection][defaultKey] = {}
      }
      toReturn[defaultSection][defaultKey] = {
        ...toReturn[defaultSection][defaultKey],
        ...defaultValue
      }
      if(toReturn[defaultSection][defaultKey].association) {
        toReturn[defaultSection][defaultKey].label = `${toReturn[defaultSection][defaultKey].label} [${toReturn[defaultSection][defaultKey].association}]`
      }
    })
  }
  let globalDefault = data['global default']
  if(globalDefault) {
    let [/*ignored*/, section] = parseJSONDefaultEntry('global',data['global default section'] || {})
    toReturn.global = {
      originalItem: globalDefault,
      label: globalDefault.split('/').pop(),
      ...section
    }

    if(toReturn.global.association) {
      toReturn.global.label = `${toReturn.global.label} [${toReturn.global.association}]`
    }
  }
  return toReturn
}

const parseJSONDefaultIndicator = (indicator, scheduleType) => {
  switch(scheduleType) {
    case 'daily': {
      return 'daily'
    }
    case 'weekly': {
      let dow = DAYS_OF_THE_WEEK.indexOf(indicator);
      if(dow !== -1) {
        return dow
      } else {
        console.warn(`When parsing the schedule's default items, tried parsing the string "${indicator}" as a weekly indicator, but it was not formatted correctly (correct format is "<weekday>")`)
        return
      }
    }
    case 'monthly': {
      let match = /dom(\d+)/.exec(indicator)
      if(!match || match.length !== 2) {
        console.warn(`When parsing the schedule's default items, tried parsing the string "${indicator}" as a monthly indicator, but it was not formatted correctly (correct format is "dom<day of month>")`)
        return
      }
      return parseInt(match[1], 10);
    }
    case 'yearly': {
      let match = /m(\d+)d(\d+)/.exec(indicator)
      if(!match || match.length !== 3) {
        console.warn(`When parsing the schedule's default items, tried parsing the string "${indicator}" as a yearly indicator, but it was not formatted correctly (correct format is "m<month>d<date>")`)
        return
      }
      let month = parseInt(match[1], 10) - 1
      let day = parseInt(match[2], 10)
      return `${month}/${day}`;
    }
    case 'interval': {
      let match = /doi(\d+)/.exec(indicator)
      if(!match || match.length !== 2) {
        console.warn(`When parsing the schedule's default items, tried parsing the string "${indicator}" as an interval indicator, but it was not formatted correctly (correct format is "doi<day of interval>")`)
        return
      }
      return parseInt(match[1], 10);
    }
    case 'endless': {
      let match = /y(\d+)m(\d+)d(\d+)/.exec(indicator)
      if(!match || match.length !== 4) {
        console.warn(`When parsing the schedule's default items, tried parsing the string "${indicator}" as an endless indicator, but it was not formatted correctly (correct fomrat is "y<year>m<month>d<date>`)
        return
      }
      let year = parseInt(match[1], 10)
      let month = parseInt(match[2], 10) - 1
      let day = parseInt(match[3], 10)
      return `${year}/${month}/${day}`;
    }
    default:
      throw new Error(`Unknown schedule type: ${scheduleType}`)
  }
}

const copyLoadSectionVars = (value) => {
  let r = {}
  let tmp;

  if ((tmp=value['item duration']) !== undefined)
    r.duration = tmp
  if ((tmp=value.in) !== undefined)
    r.in = tmp
  if ((tmp=value.out) !== undefined)
    r.out = tmp
  if ((tmp=value.offset) !== undefined)
    r.offset = tmp
  if ((tmp=value.association) !== undefined)
    r.association = tmp
  if ((tmp=value.associationName) !== undefined)
    r.associationName = tmp

  return r
}

const parseJSONDefaultEntry = (type, value) => {
  switch(type) {
    case 'daily':
      return ['daily', {originalItem: value, label: value.split('/').pop()}]
    case 'global':
      return ['global', copyLoadSectionVars(value)]
    case 'section':
      return ['daily', copyLoadSectionVars(value)]
    case 'station logo':
      if(typeof value === 'string') {
        value = {item: value}
      }
      if(!value.item) {
        return []
      }
      return ['overlayOne', {originalItem: value.item, label: value.item.split('/').pop(), ...copyLoadSectionVars(value)}]
    case 'coming up next':
      if(typeof value === 'string') {
        value = {item: value}
      }
      if(!value.item) {
        return []
      }
      return ['overlayTwo', {originalItem: value.item, label: value.item.split('/').pop(), ...copyLoadSectionVars(value)}]
    default:
      console.warn(`Unknown default entry type ${type} with value ${JSON.parse(value)}`)
      return []
  }
}

const getDefaultsSectionName = (scheduleType) => {
  let defaultsKey = 'defaults, '
  switch(scheduleType) {
    // Endless and daily use the same defaults section name for whatever reason
    case 'daily':
    case 'endless':
      defaultsKey += 'of the day'
      break
    case 'weekly':
      defaultsKey += 'day of the week'
      break
    case 'monthly':
      defaultsKey += 'day of the month'
      break
    case 'yearly':
      defaultsKey += 'day of the year'
      break
    case 'interval':
      defaultsKey += 'day of the interval'
      break
    default:
      throw new Error('Unknown schedule type: ' + scheduleType)
  }
  return defaultsKey
}

/**
 * Parses the internal defaults key to the time indicator used in defaults keys by the schedule file
 * @param {string/number} key The key of the default in the defaults state object to be parsed
 * @param {string} scheduleType The type of the schedule
 * @returns A time indicator to be passed to the parseDefaultsEntryToJSON function
 */
const parseTimeIndicator = (key, scheduleType) => {
  if(key === 'daily') {
    return 'day'
  } else if (key === 'global') {
    // Global defaults don't belong in the schedule file's defaults object. If this function is called on global defaults,
    //  null is returned to stop the global defaults from ending up in the schedule file's defaults object by accident
    return null
  } else {
    switch (scheduleType) {
      case 'daily':
        // Similarly to above, the only defaults for daily schedules are the daily defaults, and daily defaults were already handled above.
        //  Null is returned to prevent unknown defaults from ending up in the defaults object
        return null
      case 'weekly':
        if(typeof key === 'string') {
          key = parseInt(key, 10)
        }
        return DAYS_OF_THE_WEEK[key]
      case 'monthly':
        return `dom${key}`
      case 'yearly': {
        let [month, day] = key.split('/')
        month = parseInt(month, 10) + 1
        return `m${month}d${day}`
      }
      case 'endless': {
        let [year, month, day] = key.split('/')
        month = parseInt(month, 10) + 1
        return `y${year}m${month}d${day}`
      }
      case 'interval':
        return `doi${key}`
      default:
        throw new Error(`Unknown schedule type: ${scheduleType}`)
    }
  }
}

const parseDefaultsEntryToJSON = (indicator, value) => {
  let toReturn = {}
  Object.entries(value).forEach(([defaultType, defaultData]) => {
    let item = defaultData.originalItem
    if(!item) {
      return
    }
    if(!item.startsWith('/')) {
      item = `/${item}`
    }
    let defaultItem = {
      item,
      'item duration': defaultData.duration
    }
    if(defaultData.in) defaultItem.in = defaultData.in
    if(defaultData.out) defaultItem.out = defaultData.out
    if(defaultData.offset) defaultItem.out = defaultData.offset
    if(defaultData.association) defaultItem.association = defaultData.association
    if(defaultData.associationName) defaultItem.associationName = defaultData.associationName
    switch(defaultType) {
      case 'global': {
        delete defaultItem.item
        toReturn = defaultItem
        break
      }
      case 'daily': {
        let {item, ...rest} = defaultItem
        toReturn[`${indicator}`] = item
        toReturn[`section, ${indicator}`] = rest
        break
      }
      case 'overlayOne': {
        toReturn[`station logo, ${indicator}`] = defaultItem
        break
      }
      case 'overlayTwo': {
        let duration = parseFloat(defaultItem['item duration'])
        if(defaultItem.out) {
          duration = parseFloat(defaultItem.out)
        }
        if(defaultItem.in) {
          duration = duration - parseFloat(defaultItem.in)
        }
        toReturn[`coming up next, ${indicator}`] = {
          item,
          duration: `${duration}`,
          'item duration': defaultData.duration,
          in: defaultData.in,
          out: defaultData.out
        }
        break
      }
      default:
        console.warn(`Unknown type of default: ${defaultType}`)
        break
    }
  })
  return toReturn
}

export const defaultToSet = (scheduleType, defaultType, defaultTime) => {
  let defToSet = -1
  if(defaultType !== 'global') {
    switch(scheduleType) {
      case 'interval':
        defToSet = defaultTime.dayOfYear()
        break
      case 'endless':
        defToSet = `${defaultTime.year()}/${defaultTime.month()}/${defaultTime.date()}`
        break
      case 'yearly':
        defToSet = `${defaultTime.month()}/${defaultTime.date()}`
        break
      case 'monthly':
        defToSet = defaultTime.date()
        break
      case 'weekly':
        defToSet = defaultTime.day()
        break
      case 'daily':
      default:
        defToSet = 'daily'
        break
    }
  }
  if(defaultType === 'global' && scheduleType === 'daily') {
    defaultType = 'daily'
    defToSet = 'daily'
  }
  return [defaultType, defToSet]
}
