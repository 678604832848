import React from "react";
import { Dropdown, Label, Segment } from "semantic-ui-react";
import { useSelector } from "react-redux";

const ItemSettingHandler = (schema) => {
  /* This component is handling the setting called "Item".
  schema is coming from SettingPanel.jsx
  Path for channels: /mnt/main/tv/rendermgr/channel${NUMBER} and inputs: /mnt/main/tv/inputs/${NAME} 
  */

  // This is getting the current channels and inputs from Redux which will be used to populate the dropdown.
  const channels = useSelector((state) => state.settings.services.channel);
  const inputs = useSelector((state) => state.settings.services.input);

  let channelEntries = Object.entries(channels).sort((a, b) => {
    // Convert to channel numbers
    let aNum = parseInt(a[0].match(/\d+/)[0], 10)
    let bNum = parseInt(b[0].match(/\d+/)[0], 10)
    return aNum - bNum
  });
  let inputKeys = Object.keys(inputs);

  let itemSettingOptions = [];

  for (let i = 0; i < channelEntries.length; i++) {
    itemSettingOptions.push({
      key: `channel - ${channelEntries[i][0]}`,
      text: channelEntries[i][1].settings.name || `Channel ${channelEntries[i][0].match(/\d+/)[0]}`,
      value: `/mnt/main/tv/rendermgr/${channelEntries[i][0]}`
    })
  }

  // Pushing the input path
  for (let i = 0; i < inputKeys.length; i++) {
    itemSettingOptions.push({
      key: `input - ${inputKeys[i]}`,
      text: inputKeys[i],
      value: `/mnt/main/tv/inputs/${inputKeys[i]}`
    })
  }

  let dropdownValue = schema.value;
  // We are having to filter out null / undefined values otherwise
  // an error gets thrown since dropdown values can't contain null or undefined types
  if (!dropdownValue) {
    dropdownValue = " ";
  }

  return (
    <>
      <Segment>
        <Label size="large">{schema.display_name}</Label>
        <Dropdown
          options={itemSettingOptions}
          placeholder=""
          onChange={schema.onChange}
          value={dropdownValue}
          selection
        />
        <div style={{ padding: "0.5em 0 0 0.5em" }}>{schema.description}</div>
      </Segment>
    </>
  );
};

export default ItemSettingHandler;
