import {fetchFileFromServer} from 'helpers/net_helpers'
import {saveFile} from 'redux/file_list'
import {messages} from 'redux/messages'
import tabbedReducer from 'redux/higher_order_reducers/tabbedReducer'
import {parseMetadataTemplate,
  serializeMetadataTemplate,
  mergeMetadata,
  parseContentWindow,
  serializeContentWindow,
  sanitizeMetadataTags} from 'helpers/metadata_helpers'
import loaderReducer from 'redux/higher_order_reducers/loaderReducer'
import {actions as loading} from 'redux/higher_order_reducers/loaderReducer'
import {validateURL} from 'helpers/general_helpers'

export const CHANGE_TEMPLATE_DATA = Symbol('change template data')
export const RESET_SWAP_TEMPLATE_DATA = Symbol('reset swap template data')
export const LOAD_METADATA_TEMPLATE = Symbol('load metadata template')

export const TEMPLATE_CHANGE_TAB = Symbol('metadata template change tab')
export const TEMPLATE_CREATE_TAB = Symbol('metadata template create tab')
export const TEMPLATE_DELETE_TAB = Symbol('metadata template delete tab')

export const actions = {

  /**
   * Asynchronously loads the metadata template at path into the store's data
   * @param {array} path The path to load the metadata template from
   */
  loadMetadataTemplate: (path) => {
    return async (dispatch, getState) => {
      let jobId = dispatch(loading.startLoading(getState().local._loaderID))
      let data = await fetchFileFromServer(path.join('/'))
      let contentType = data.headers.get("Content-Type")
      if(contentType.startsWith('video') || contentType.startsWith('audio')) {
        dispatch(loading.finishLoading(getState().local._loaderID, jobId))
        dispatch.global(messages.alert(`File /${path.join('/')} is not a metadata template.`, {level: 'error'}))
        return
      }
      if(data.ok) {
        data = await data.text()
        dispatch(loading.finishLoading(getState().local._loaderID, jobId))
        try {
          data = parseMetadataTemplate(data)
        } catch (err) {
          if(err.type === 'NOT_METADATA_TEMPLATE') {
            dispatch.global(messages.alert(`File /${path.join('/')} is not a metadata template.`, {level: 'error'}))
            return
          } else {
            throw err
          }
        }

        // SPECIAL CASE: "Content Window Open" and "Content Window Close" must be formatted as YYYY-MM-DD HH:mm:ss UTC
        if("content window open" in data) {
          let contentWindowOpen = data["content window open"]
          try {
            contentWindowOpen = parseContentWindow(contentWindowOpen)
          } catch (err) {
            // Ok, just try parsing it as a date?
            contentWindowOpen = new Date(contentWindowOpen)
          }
          data = {
            ...data,
            "content window open": contentWindowOpen
          }
        }
        if("content window close" in data) {
          let contentWindowclose = data["content window close"]
          try {
            contentWindowclose = parseContentWindow(contentWindowclose)
          } catch (err) {
            // Ok, just try parsing it as a date?
            contentWindowclose = new Date(contentWindowclose)
          }
          data = {
            ...data,
            "content window close": contentWindowclose
          }
        }
        data = sanitizeMetadataTags(data)

        dispatch({
          type: LOAD_METADATA_TEMPLATE,
          payload: {
            data,
            path
          }
        })
      } else if (data.status === 404) {
        dispatch(loading.finishLoading(getState().local._loaderID, jobId))
        dispatch.global(messages.alert(`The file /${path.join('/')} was not found.`, {level: 'error'}))
      } else {
        let reason = await data.text()
        dispatch(loading.finishLoading(getState().local._loaderID, jobId))
        dispatch.global(messages.alert(`There was an error loading the metadata template /${path.join('/')}: ${reason}`, {level: 'error'}))
      }
    }
  },

  saveMetadataTemplate: () => {
    return async (dispatch, getState) => {
      let {filepath, filename, templateData, swapData} = getState().local
      if("external link" in swapData && !validateURL(swapData["external link"])) {
        let keepGo = await dispatch.global(messages.confirmAsync("The value you entered for the External Link metadata tag is not a valid url. Are you sure you want to save the metadata as is?"))
        if(!keepGo) {
          return
        }
      }

      // SPECIAL CASE: "Content Window Open" and "Content Window Close" must be formatted as YYYY-MM-DD HH:mm:ss UTC
      if("content window open" in swapData) {
        let contentWindowOpen = swapData["content window open"]
        if(contentWindowOpen) {
          swapData = {
            ...swapData,
            "content window open": serializeContentWindow(contentWindowOpen)
          }
        }
      }
      if("content window close" in swapData) {
        let contentWindowClose = swapData["content window close"]
        if(contentWindowClose) {
          swapData = {
            ...swapData,
            "content window close": serializeContentWindow(contentWindowClose)
          }
        }
      }


      let templateFile = serializeMetadataTemplate(sanitizeMetadataTags(mergeMetadata(templateData, swapData)))
      let dest = filepath.join('/')
      let name = filename
      if(!dest) {
        dest = 'mnt/main/Metadata Templates'
      }
      if(!name) {
        name = await dispatch.global(messages.promptAsync("What do you want to name this metadata template?"))
        if(!name) {
          return
        }
      }
      let source = new File([templateFile], name, {type: 'text/plain'})
      let jobId = dispatch(loading.startLoading(getState().local._loaderID))
      await dispatch.global(saveFile(source, dest, {
        createMetadata: true,
        onSuccess: () => {dispatch.global(messages.alert(`Metadata template /${dest}/${name} saved!`, {level: 'success'}))},
        onError: (res) => {dispatch.global(messages.alert("There was an error saving the metadata template!", {level: 'error'}))}
      }))
      dispatch(loading.finishLoading(getState().local._loaderID, jobId))
      let path = [...dest.split('/'), name]
      await dispatch(actions.loadMetadataTemplate(path))
      dispatch({type: RESET_SWAP_TEMPLATE_DATA})
    }
  },

  /**
   * Changes the swap metadata
   * @param {string} key The metadata key to change
   * @param {string} value The value to change the given key to
   */
  changeTemplateData: (key, value) => (
    {
      type: CHANGE_TEMPLATE_DATA,
      payload: {
        key,
        value
      }
    }
  ),

  /**
   * Removes swap data, reverting to just the file's current metadata
   */
  resetSwapTemplateData: () => {
    return (dispatch) => {
      dispatch.global(messages.confirm('Are you sure you want to revert all unsaved changes?', (result) => {
        if(result) {
          dispatch({type: RESET_SWAP_TEMPLATE_DATA})
        }
      }))
    }
  },

}

export const initialState = {
  filepath: [],                        // folder path of file being edited
  filename: '',                        // name of file being edited
  swapData: {},                        // object containing changes made to file's metadata that have not yet been saved.
  templateData: {},                    // if editing a template, this is the template's base data
}

const reducer = (state=initialState, action) => {
  let {type, payload} = action

  switch(type) {
    case CHANGE_TEMPLATE_DATA:
      let {key, value} = payload
      console.log(value instanceof Date)
      return {
        ...state,
        swapData: {
          ...state.swapData,
          [key.toLowerCase()]: value
        }
      }
    case RESET_SWAP_TEMPLATE_DATA:
      return {
        ...state,
        swapData: {}
      }
    case LOAD_METADATA_TEMPLATE: {
      let {path, data} = payload
      return {
        ...state,
        templateData: data,
        filepath: path.slice(0, -1),
        filename: path.slice(-1)[0],
        swapData: {}
      }
    }
    default:
      return state
  }
}

export const changeTab = (index) => ({
  type: TEMPLATE_CHANGE_TAB,
  payload: index
})

export const createTab = (path) => {
  let payload = {}
  if(path) {
    payload = {
      filepath: path.slice(0, -1),
      filename: path.slice(-1).join()
    }
  }
  return {
    type: TEMPLATE_CREATE_TAB,
    payload
  }
}

export const deleteTab = (index) => ({
  type: TEMPLATE_DELETE_TAB,
  payload: index
})

const TAB_DISPLAY = (props) => {
  if(props.filename) {
    return props.filename
  } else {
    return '(NEW METADATA TEMPLATE)'
  }
}

const TAB_IS_UNSAVED = (props) => {
  return Object.keys(props.swapData).length > 0
}

const onTabCreated = () => {
  return (dispatch, getState) => {
    let {filepath, filename} = getState().local
    if(filepath.length > 0 && filename) {
      let fullpath = [...filepath, filename]
      dispatch(actions.loadMetadataTemplate(fullpath))
    }
  }
}

const loadingReducer = loaderReducer(reducer, initialState);

export default tabbedReducer('template_editor',
  loadingReducer,
  initialState,
  {
    changeTab: TEMPLATE_CHANGE_TAB,
    createTab: TEMPLATE_CREATE_TAB,
    deleteTab: TEMPLATE_DELETE_TAB,
    display: TAB_DISPLAY,
    tabActions: actions,
    isUnsaved: TAB_IS_UNSAVED,
    onTabCreated
  }
)
