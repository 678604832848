import React, {PureComponent} from 'react'
import {
  Modal,
  Header,
  List,
  Image,
  Segment,
  Dimmer,
  Loader,
  Form,
  Dropdown,
  Message,
  Button
} from 'semantic-ui-react'
import {staticAsset} from 'helpers/net_helpers'
//import {getIn} from 'helpers/general_helpers'

const canCaption = (conf) => {
  return (conf && conf.conf && conf.conf.captions === "pro")
}

// This component is used in the container for the Library component
export default class SendToVodModal extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      selectedPlaylists: [],
      captionFile: null
    }
    this.mountedRef = React.createRef()
  }

  componentDidUpdate(prevprops) {
    let isOpen = !!(this.props.files && this.props.files.length)
    let wasOpen = !!(prevprops.files && prevprops.files.length)
    if(isOpen && !wasOpen) {
      // Commented out captionFile automatic settings by request of Nathan so that captioning must be manually set by the client
      //let allFilesHaveCaptions = !(this.props.files.find((file) => !file.captions || !file.captions.length))
      this.setState({loading: true/*, captionFile: (canCaption(this.props.vodConfig) && !allFilesHaveCaptions)*/})
      this.props.fetchCloudPlaylists()
        .then(() => {
          if(this.mountedRef) {
            this.setState({loading: false})
          }
        })
        .catch((err) => {
          console.error("Error fetching playlists:")
          console.error(err)
        })
    } else if(!isOpen && wasOpen) {
      // clear state when closed
      this.setState({loading: true, selectedPlaylists: [], captionFile: null})
      if(this.props.playlistsError) {
        this.props.clearPlaylistsError()
      }
    }
  }

  handleSubmit = () => {
    let {files, sendToVod, closeModal} = this.props
    let {selectedPlaylists, captionFile} = this.state
    let toSend = files.map((file) => (file.path || file))
    let opts = {}
    if(selectedPlaylists && selectedPlaylists.length) {
      opts.playlists = selectedPlaylists
    }
    if(captionFile !== null) {
      opts.caption = captionFile
    }
    closeModal()
    this.setState({loading: true, selectedPlaylists: [], captionFile: null})
    sendToVod(toSend, opts)
  }

  render() {
    let {
      files,
      vodConfig,
      cloudPlaylists,
      playlistsError,
      closeModal
    } = this.props
    let {
      loading,
      selectedPlaylists,
      captionFile
    } = this.state
    let isOpen = !!(files && files.length)

    let filelist = []
    if(files) {
      filelist = files.map((file) => {
        if(!file) {
          return ""
        } else if(file.icon) {
          return (
            <List.Item key={file.path.join("/")}>
              <List.Icon className='svg-icon' size="large">
                <Image src={staticAsset(`/icons/${file.icon}.svg`)}/>
              </List.Icon>
              {file.path[file.path.length - 1]}
            </List.Item>
          )
        } else {
          return (<List.Item key={file.join("/")}>{file[file.length - 1]}</List.Item>)
        }
      })
    }
    let playlistOptions = []
    if(cloudPlaylists) {
      playlistOptions = cloudPlaylists.map((playlist) => ({key: playlist._id, text: playlist.name, value: playlist._id}))
    }

    let playlistsDropdown = ""
    if(playlistsError) {
      playlistsDropdown = (
        <Message negative>
          <Message.Header>Error fetching cloud playlists:</Message.Header>
          {playlistsError}
        </Message>
      )
    } else if(playlistOptions.length) {
      playlistsDropdown = (
        <Form.Field>
          <label>Add file(s) to playlist(s):</label>
          <Dropdown fluid
            multiple
            selection
            search
            value={selectedPlaylists}
            onChange={(e, data) => {this.setState({selectedPlaylists: data.value})}}
            options={playlistOptions}
          />
        </Form.Field>
      )
    }

    let captionsEnabled = canCaption(vodConfig)
    //let allFilesHaveCaptions = !(files.find((file) => !file.captions || !file.captions.length))
    // TODO replace captionsEnabled below with some kind of user preference
    //let shouldCaption = captionFile === null ? (captionsEnabled && !allFilesHaveCaptions) : captionFile

    return (
      <Modal open={isOpen} closeIcon onClose={() => closeModal()} ref={this.mountedRef}>
        <Modal.Header>Send Files to Video On Demand</Modal.Header>
        <Modal.Content>
          <Segment>
            <Header>The following file(s) will be sent to Video on Demand:</Header>
            <List>
              {filelist}
            </List>
          </Segment>
          <Segment>
            <Header>Options:</Header>
            <Dimmer active={loading}>
              <Loader>Fetching List of Playlists from Cloud...</Loader>
            </Dimmer>
            <Form>
              {playlistsDropdown}
              <Form.Checkbox
                toggle
                label={"Caption File"}
                disabled={!captionsEnabled}
                checked={/*shouldCaption*/captionFile}
                onChange={(e, data) => {this.setState({captionFile: data.checked})}}
              />
            </Form>
          </Segment>
        </Modal.Content>
        <Modal.Actions>
          <Button icon="send" primary onClick={this.handleSubmit} content="Send Files"/>
          <Button icon="remove" secondary onClick={closeModal} content="Cancel"/>
        </Modal.Actions>
      </Modal>
    )
  }

}
